import React from 'react';
import { MDBIcon, MDBNavLink } from 'mdbreact';

const btnLinks = ({ to, title, icon }) => {
	return (
		<MDBNavLink className="border nav-link border-light rounded mr-1 mx-2 mb-2" to={to}>
			<MDBIcon icon={icon} />
			<span className="font-weight-bold"> {title}</span>
		</MDBNavLink>
	);
};
export default btnLinks;
