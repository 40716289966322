import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBContainer } from 'mdbreact';
import Api from '../../../../services/Api';
import { REST_KEY } from '../../../../config';
import { store } from 'react-notifications-component';

class HapusPasar extends Component {
	state = {
		modalHapus: false,
		token_api: REST_KEY,
	};

	toggle = () => {
		this.setState({
			modalHapus: !this.state.modalHapus,
			idPasar: this.props.idPasar,
			namaPasar: this.props.namaPasar,
		});
	};

	handlerChanges = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('master/hapuspasar', this.state).then((response) => {
			let status = response.data;
			this.setState({ message: status.message, title: status.title, type: status.type });
		});
		this.setState({
			modalHapus: false,
		});
		store.addNotification({
			title: this.state.title,
			message: this.state.message,
			type: this.state.type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};

	render() {
		return (
			<>
				<button className="btn btn-danger btn-small px-2" href="#" onClick={this.toggle}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>
				<MDBModal
					isOpen={this.state.modalHapus}
					toggle={this.toggle}
					className="mw-100"
					fullHeight
					position="right"
				>
					<MDBModalHeader toggle={this.toggle} className="text-center"></MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="text-center">
								<MDBContainer fluid>
									Apakah anda akan menghapus Data
									<span className="text-success"> {this.state.namaPasar}</span>??
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="danger" type="submit">
									Hapus
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default HapusPasar;
