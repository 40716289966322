import React, { Component } from 'react';
import Api from '../../../services/Api';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import CartPNG from '../../../assets/cart.png';
import CartShadow from '../../../assets/cart.png';
import { MDBDataTable } from 'mdbreact';
import { WS_URL, REST_KEY } from '../../../config';

class PenandaPasar extends Component {
	state = {
		token_api: REST_KEY,
		loading: false,
		idPasar: this.props.idPasar,
	};

	componentDidMount = async () => {
		await Api.post('maps/pasardetail', this.state).then((response) => {
			let data = response.data;
			let { rows } = response.data;
			let columns = [
				{
					label: 'Komoditi',
					field: 'A',
					width: 1,
				},
				{
					label: 'Satuan',
					field: 'B',
					width: 1,
				},
				{
					label: 'Harga',
					field: 'C',
					width: 1,
				},
				{
					label: 'Tanggal',
					field: 'D',
					width: 1,
				},
			];
			rows = rows.map((row) => ({
				...row,
				A: (
					<>
						<p className="tablemini">{row.namaKomoditi}</p>
					</>
				),
				B: (
					<>
						<p className="tablemini">{row.satuan}</p>
					</>
				),
				C: (
					<>
						<p className="tablemini">Rp {row.harga2}</p>
					</>
				),
				D: (
					<>
						<p className="tablemini">{row.tanggal2}</p>
					</>
				),
			}));
			data = {
				columns,
				rows,
			};
			this.setState({ data, loading: false, kategori: this.props.kategori });
		});
	};

	render() {
		const CartIcon = L.icon({
			iconUrl: CartPNG,
			iconShadow: CartShadow,
			iconSize: [38, 95], // size of the icon
			shadowSize: [50, 64], // size of the shadow
			iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
			shadowAnchor: [4, 62], // the same for the shadow
			popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
		});
		const { data } = this.state;
		return (
			<Marker position={[this.props.lat, this.props.lng]} icon={CartIcon}>
				<Popup className="gamps">
					<div>
						<h4 className="text-center">{this.props.nama}</h4>
						<img
							className="img-fluid z-depth-1"
							src={WS_URL + 'assets/upload/pasar/thumbs/' + this.props.foto}
							alt="thumbnail"
						/>
						<hr />
						<h6 className="text-center">Daftar Harga Terbaru</h6>
						<MDBDataTable
							scrollY
							entries={3}
							pagesAmount={3}
							info={false}
							entriesLabel=""
							searchLabel=" "
							paginationLabel={['<', '> ']}
							striped
							bordered
							data={data}
							sortRows={['id']}
						/>
					</div>
				</Popup>
			</Marker>
		);
	}
}

export default PenandaPasar;
