import React from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import Api from '../../../../services/Api';
import { REST_KEY } from '../../../../config';
import EditPasar from './EditPasar';
import TambahPasar from './TambahPasar';
import HapusPasar from './HapusPasar';
import ViewImagePasar from './ViewImagePasar';

class Pasar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
			token_api: REST_KEY,
		};
	}

	componentDidMount = async () => {
		await Api.post('master/listpasar', this.state).then((response) => {
			this.setState({
				kelurahan: response.data.kelurahan,
			});
			let data = response.data;
			let { rows } = response.data;
			let columns = [
				{
					label: 'Action',
					field: 'action',
					width: 75,
				},
				{
					label: 'Pasar/Warung/Toko',
					field: 'namaPasar',
					width: 120,
				},
				{
					label: 'Deskripsi',
					field: 'deskPasar',
					width: 100,
				},
				{
					label: 'Kelurahan/Desa',
					field: 'namaKelurahanDesa',
					width: 100,
				},
				{
					label: 'Kecamatan',
					field: 'namaKecamatan',
					width: 100,
				},
			];

			rows = rows.map((row) => ({
				...row,
				action: (
					<MDBRow className="ml-1">
						<EditPasar
							kelurahan={this.state.kelurahan}
							idKelurahanDesa={row.idKelurahanDesa}
							idPasar={row.idPasar}
							namaKelurahanDesa={row.namaKelurahanDesa}
							namaPasar={row.namaPasar}
							deskPasar={row.deskPasar}
							fotoPasar={row.fotoPasar}
							longitude={row.longitude}
							latitude={row.latitude}
							refresh={this.componentDidMount}
						/>
						<HapusPasar namaPasar={row.namaPasar} idPasar={row.idPasar} refresh={this.componentDidMount} />
						<ViewImagePasar fotoPasar={row.fotoPasar} namaPasar={row.namaPasar} />
					</MDBRow>
				),
			}));

			data = {
				columns,
				rows,
			};
			this.setState({ data, loading: false });
		});
	};

	render() {
		const { data } = this.state;
		const loading = this.state.loading;
		return (
			<MDBContainer className="mt-3" fluid>
				<TambahPasar kelurahan={this.state.kelurahan} refresh={this.componentDidMount} />
				<MDBRow className="py-3">
					<MDBCol md="12">
						<MDBCard>
							<MDBCardBody>
								<h5 className="text-center">Daftar Pasar/Toko/Warung</h5>
								<MDBDataTable scrollX striped bordered data={data} sortRows={['id']} />
								{loading && (
									<div className="text-center">
										<p>Loading....</p>
										<div className="spinner-border text-primary" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-success" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-warning" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-info" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default Pasar;

//import NumberFormat from 'react-number-format';
/* <NumberFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */
