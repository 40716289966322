import React, { Component } from 'react';
import {
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBCol,
	MDBNavItem,
	MDBTooltip,
	MDBIcon,
	MDBBtn,
	MDBBtnGroup,
} from 'mdbreact';
import { store } from 'react-notifications-component';

class Keluar extends Component {
	state = {
		modalKeluar: false,
	};

	toggle = () => {
		this.setState({
			modalKeluar: !this.state.modalKeluar,
		});
	};
	keluar = () => {
		sessionStorage.clear();
		store.addNotification({
			title: 'Berhasil!',
			message: 'Anda Telah Logout/Keluar',
			type: 'success',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 3000,
				onScreen: true,
			},
		});
		setTimeout(() => {
			this.setState((window.location.href = '/'));
		}, 2000);
	};
	render() {
		return (
			<>
				<MDBNavItem onClick={this.toggle}>
					<MDBTooltip placement="bottom" domElement style={{ display: 'block' }}>
						<p className="nav-link Ripple-parent mb-0 pb-0">
							<strong>
								<MDBIcon icon="sign-out-alt" />
							</strong>
						</p>
						<span>Keluar</span>
					</MDBTooltip>
				</MDBNavItem>
				<MDBModal isOpen={this.state.modalKeluar} toggle={this.toggle} position="right">
					<MDBModalHeader toggle={this.toggle}></MDBModalHeader>
					<MDBModalBody>
						<MDBCol>
							<h5 className="text-center">Apakah Anda Akan keluar/logout?</h5>
						</MDBCol>
						<MDBCol md="12" className="mb-4 text-center">
							<MDBBtnGroup className="text-center">
								<MDBBtn className="m-2" color="danger" onClick={this.keluar}>
									Ya
								</MDBBtn>
								<MDBBtn className="m-2" color="warning" onClick={this.toggle}>
									Batal
								</MDBBtn>
							</MDBBtnGroup>
						</MDBCol>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default Keluar;
