import React, { Component } from 'react';
import {
	// MDBCardTitle,
	// MDBCardBody,
	// MDBCard,
	// MDBBtn,
	// MDBCardText,
	// MDBCardGroup,
	MDBContainer,
	// MDBIcon,
	// MDBDropdownItem,
	// MDBDropdown,
	// MDBDropdownToggle,
	// MDBDropdownMenu,
	// MDBNavLink,
} from 'mdbreact';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import SideNavs from '../../../components/AdminPage/SideNav';
import LinkBar from './LinkBar';

export default class Master extends Component {
	render() {
		return (
			<>
				<div>
					<SideNavs />
					<ReactNotification />
					<MDBContainer fluid>
						{/* <h4 className="text-center mt-2">Referensi/Master</h4>
						<hr></hr>
						<MDBCardGroup className="mt-4">
							<MDBCard>
								<MDBBtn size="lg" gradient="purple">
									<MDBIcon icon="atlas" size="10x" />
								</MDBBtn>
								<MDBCardBody>
									<MDBCardTitle tag="h5">Master Wilayah</MDBCardTitle>
									<MDBCardText>
										Untuk melakukan input Kecamatan, Desa dan Pasar. Pastikan untuk mengisi
										kecamatan terlebih dulu, kemudian mengisi Desa/Kelurahan lalu dilanjutkan dengan
										Pasar/Toko/Warung
									</MDBCardText>
									<MDBDropdown>
										<MDBDropdownToggle caret gradient="purple">
											Pilih
										</MDBDropdownToggle>
										<MDBDropdownMenu basic>
											<MDBDropdownItem header>Master Wilayah</MDBDropdownItem>
											<MDBNavLink to="/admin/master/kecamatan">
												<strong>KECAMATAN</strong>
											</MDBNavLink>
											<MDBNavLink to="/admin/master/kelurahan_desa">
												<strong>DESA/KELURAHAN</strong>
											</MDBNavLink>
											<MDBNavLink to="/admin/master/pasar">
												<strong>PASAR/TOKO/WARUNG</strong>
											</MDBNavLink>
										</MDBDropdownMenu>
									</MDBDropdown>
								</MDBCardBody>
							</MDBCard>

							<MDBCard>
								<MDBBtn size="lg" gradient="peach">
									<MDBIcon icon="carrot" size="10x" />
								</MDBBtn>
								<MDBCardBody>
									<MDBCardTitle tag="h5">Master Komoditas</MDBCardTitle>
									<MDBCardText>
										Untuk melakukan input Kategori Barang, Sub Komoditi dan Komoditi. Pastikan untuk
										mengisi Kategori Barang terlebih dahulu kemudian mengisi Sub Komoditi
										dilanjutkan dengan Komoditi
									</MDBCardText>
									<MDBDropdown>
										<MDBDropdownToggle caret gradient="peach">
											Pilih
										</MDBDropdownToggle>
										<MDBDropdownMenu basic>
											<MDBDropdownItem header>Master Komoditas</MDBDropdownItem>
											<MDBNavLink to="/admin/master/kategori_barang">
												<strong>KATEGORI BARANG</strong>
											</MDBNavLink>
											<MDBNavLink to="/admin/master/satuan">
												<strong>SATUAN</strong>
											</MDBNavLink>
											<MDBNavLink to="/admin/master/sub_komoditi">
												<strong>SUB KOMODITAS</strong>
											</MDBNavLink>
											<MDBNavLink to="/admin/master/komoditi">
												<strong>KOMODITAS</strong>
											</MDBNavLink>
										</MDBDropdownMenu>
									</MDBDropdown>
								</MDBCardBody>
							</MDBCard>
						</MDBCardGroup> */}
						<LinkBar />
					</MDBContainer>
				</div>
			</>
		);
	}
}
