import React, { Component } from 'react';
import { PDK, WS_URL, REST_KEY } from '../../../config';

class EditKoperasi extends Component {
	state = {
		token_api: REST_KEY,
		print_url: WS_URL + PDK,
	};

	render() {
		return (
			<form action={this.state.print_url} method="post" target="_blank">
				<input name="idKoperasi" value={this.props.idKoperasi} hidden readOnly />
				<button
					className="btn btn-success btn-small px-2"
					type="submit"
					name="token_api"
					value={this.state.token_api}
				>
					<i className="fa fa-print" aria-hidden="true"></i>
				</button>
			</form>
		);
	}
}

export default EditKoperasi;
