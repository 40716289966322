import React from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import SideNavs from '../../../components/NavBar/Navbar';
import Api from '../../../services/Api';
import PrintKoperasi from './PrintKoperasi';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

class Koperasi extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
		};
	}

	componentDidMount = async () => {
		let params = {
			token_api: 'defaultrestkey',
		};
		await Api.post('koperasi/list', params).then((response) => {
			let data = response.data;
			let { rows } = response.data;
			let columns = [
				{
					label: 'Action',
					field: 'action',
					width: 120,
				},
				{
					label: 'Nama Koperasi',
					field: 'namaKoperasi',
					sort: 'asc',
					width: 100,
				},
				{
					label: 'Status Koperasi',
					field: 'statusKoperasi',
					sort: 'asc',
					width: 100,
				},
				{
					label: 'NIK Koperasi',
					field: 'NIKKoperasi',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Alamat',
					field: 'alamat',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Desa/Kelurahan',
					field: 'desaKelurahan',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Kab/Kota',
					field: 'kabupatenKota',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Nama Manager',
					field: 'namaManager',
					sort: 'asc',
					width: 150,
				},
			];

			rows = rows.map((row) => ({
				...row,
				action: (
					<MDBRow className="ml-1">
						<PrintKoperasi idKoperasi={row.idKoperasi} />
					</MDBRow>
				),
			}));
			data = {
				columns,
				rows,
			};
			this.setState({ data, loading: false });
		});
	};

	render() {
		const { data } = this.state;
		const loading = this.state.loading;
		return (
			<MDBContainer className="mt-3" fluid>
				<ReactNotification />
				<SideNavs />
				<MDBRow className="py-3">
					<MDBCol md="12">
						<MDBCard>
							<MDBCardBody>
								<h3 className="text-center">Daftar Koperasi</h3>
								<MDBDataTable striped bordered hover scrollX data={data} sortRows={['id']} />
								{loading && (
									<div className="text-center">
										<p>Loading....</p>
										<div className="spinner-border text-primary" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-success" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-warning" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-info" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default Koperasi;

//import NumberFormat from 'react-number-format';
/* <NumberFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */
