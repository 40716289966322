import React, { Component } from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import CartPNG from '../../../assets/umkm.png';
import CartShadow from '../../../assets/umkm.png';

class PenandaUMKM extends Component {
	state = {
		loading: false,
		id: this.props.id,
	};
	render() {
		const CartIcon = L.icon({
			iconUrl: CartPNG,
			iconShadow: CartShadow,
			iconSize: [38, 95], // size of the icon
			shadowSize: [50, 64], // size of the shadow
			iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
			shadowAnchor: [4, 62], // the same for the shadow
			popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
		});
		return (
			<Marker position={[this.props.lat, this.props.lng]} icon={CartIcon}>
				<Popup className="gamps">
					<div>
						<h4 className="text-center">{this.props.nama}</h4>
						<hr />
						<h6 className="text-center">Nama Pemilik {this.props.message}</h6>
					</div>
				</Popup>
			</Marker>
		);
	}
}

export default PenandaUMKM;
