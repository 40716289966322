import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBInput, MDBCol } from 'mdbreact';
import Api from '../../../services/Api';
import { store } from 'react-notifications-component';

class DelUser extends Component {
	state = {
		modal: false,
		idUser: this.props.idUser,
		namaUser: this.props.namaUser,
		token_api: 'defaultrestkey',
	};

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('userapi/del', this.state);
		this.setState({
			modal: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data ' + this.props.namaUser + ' berhasil di Hapus',
			type: 'warning',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};
	render() {
		return (
			<>
				<MDBBtn className="btn-floating btn-lg btn-unique text-success" onClick={this.toggle}>
					<i className="fas fa-trash p-3"></i>
				</MDBBtn>

				<MDBModal isOpen={this.state.modal} toggle={this.toggle} fullHeight position="right">
					<MDBModalHeader toggle={this.toggle}>
						Apakah Anda Akan Menghapus Data <span className="text-danger">{this.props.namaUser}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<MDBCol>
							<form onSubmit={this.handlerSubmit}>
								<div className="grey-text text-left">
									<MDBInput name="idUser" value={this.state.idUser} hidden />
								</div>
								<div className="text-center">
									<MDBBtn color="primary" type="submit">
										Iya
									</MDBBtn>
								</div>
							</form>
						</MDBCol>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default DelUser;
