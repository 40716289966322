import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Navbar from './components/NavBar/Navbar';

import HomePage from './controllers/HomePage/HomePage';
import AdminPage from './controllers/AdminPage/Dashboard/AdminPage';
import ManajemenUser from './controllers/AdminPage/UserManajemen/UserManajemen';

import Master from './controllers/AdminPage/Master/Master';
import Kecamatan from './controllers/AdminPage/Master/Kecamatan/Kecamatan';
import Kelurahan from './controllers/AdminPage/Master/Kelurahan/Kelurahan';
import Pasar from './controllers/AdminPage/Master/Pasar/Pasar';
import KategoriBarang from './controllers/AdminPage/Master/KategoriBarang/KategoriBarang';
import Satuan from './controllers/AdminPage/Master/Satuan/Satuan';
import SubKomoditi from './controllers/AdminPage/Master/SubKomoditi/SubKomoditi';
import Komoditi from './controllers/AdminPage/Master/Komoditi/Komoditi';

import PerdaganganUser from './controllers/HomePage/Perdagangan/Perdagangan';
import KoperasiUser from './controllers/HomePage/Koperasi/Koperasi';
import UMKMUser from './controllers/HomePage/Umkm/Umkm';
import StatsUser from './controllers/HomePage/Stats/Stats';
import MapsUser from './controllers/HomePage/Maps/Maps';

import Perdagangan from './controllers/AdminPage/Perdagangan/Perdagangan';
import Koperasi from './controllers/AdminPage/Koperasi/Koperasi';
import Umkm from './controllers/AdminPage/Umkm/Umkm';

import Maps from './controllers/AdminPage/Maps/Maps';

// import NavigationNavPage from './zMDBDocx/pages/NavigationNavPage';
// import FormsNavPage from './zMDBDocx/pages/FormsNavPage';
// import TablesNavPage from './zMDBDocx/pages/TablesNavPage';
// import AddonsNavPage from './zMDBDocx/pages/AddonsNavPage';
// import ModalsNavPage from './zMDBDocx/pages/ModalsNavPage';
// import AdvancedNavPage from './zMDBDocx/pages/AdvancedNavPage';
// import ComponentsNavPage from './zMDBDocx/pages/ComponentsNavPage';

// FREE
// import AnimationPage from './zMDBDocx/pages/AnimationPage';
// import AlertPage from './zMDBDocx/pages/AlertPage';
// import ButtonPage from './zMDBDocx/pages/ButtonPage';
// import CSSNavPage from './zMDBDocx/pages/CSSNavPage';
// import TablePage from './zMDBDocx/pages/TablePage';
// import TableResponsivePage from './zMDBDocx/pages/TableResponsivePage';
// import TableScrollPage from './zMDBDocx/pages/TableScrollPage';
// import TableStylesPage from './zMDBDocx/pages/TableStylesPage';
// import BadgePage from './zMDBDocx/pages/BadgePage';
// import BreadcrumbPage from './zMDBDocx/pages/BreadcrumbPage';
// import FaPage from './zMDBDocx/pages/FaPage';
// import DatatablePage from './zMDBDocx/pages/DatatablePage';
// import DatatableApiPage from './zMDBDocx/pages/DatatableApiPage';
// import ModalPage from './zMDBDocx/pages/ModalPage';
// import ModalFormPage from './zMDBDocx/pages/ModalFormPage';
// import ModalExamplesPage from './zMDBDocx/pages/ModalExamplesPage';
// import ProgressPage from './zMDBDocx/pages/ProgressPage';
// import InputPage from './zMDBDocx/pages/InputPage';
// import MediaPage from './zMDBDocx/pages/MediaPage';
// import JumbotronPage from './zMDBDocx/pages/JumbotronPage';
// import CardsPage from './zMDBDocx/pages/CardsPage';
// import PaginationPage from './zMDBDocx/pages/PaginationPage';
// import PopoverPage from './zMDBDocx/pages/PopoverPage';
// import ListGroupPage from './zMDBDocx/pages/ListGroupPage';
// import CarouselPage from './zMDBDocx/pages/CarouselPage';
// import PanelPage from './zMDBDocx/pages/PanelPage';
// import CollapsePage from './zMDBDocx/pages/CollapsePage';
// import TooltipsPage from './zMDBDocx/pages/TooltipsPage';
// import FooterPage from './zMDBDocx/pages/FooterPage';
// import MasksPage from './zMDBDocx/pages/MasksPage';
// import DropdownPage from './zMDBDocx/pages/DropdownPage';
// import VideoCarouselPage from './zMDBDocx/pages/VideoCarouselPage';
// import HoverPage from './zMDBDocx/pages/HoverPage';
// import FormsPage from './zMDBDocx/pages/FormsPage';
// import ChartsPage from './zMDBDocx/pages/ChartsPage';
// import SearchPage from './zMDBDocx/pages/SearchPage';
// import ValidationPage from './zMDBDocx/pages/ValidationPage';
// import NavbarPage from './zMDBDocx/pages/NavbarPage';
// import IframePage from './zMDBDocx/pages/IframePage';
// import EdgeHeaderPage from './zMDBDocx/pages/EdgeHeaderPage';
// import SpinnerPage from './zMDBDocx/pages/SpinnerPage';
// import MasonryPage from './zMDBDocx/pages/MasonryPage';
// import ScrollBarPage from './zMDBDocx/pages/ScrollBarPage';
// import NavsPage from './zMDBDocx/pages/NavsPage';
// import TabsPage from './zMDBDocx/pages/TabsPage';
// import PillsPage from './zMDBDocx/pages/PillsPage';
// import NotificationPage from './zMDBDocx/pages/NotificationPage';
// import InputGroupPage from './zMDBDocx/pages/InputGroupPage';
// import TreeviewPage from './zMDBDocx/pages/TreeviewPage';
// import RatingPage from './zMDBDocx/pages/RatingPage';

class Routes extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path="/" component={HomePage} />
				<Route exact path="/admin/dashboard" component={AdminPage} />
				<Route exact path="/admin/manajemenuser" component={ManajemenUser} />

				<Route exact path="/admin/master" component={Master} />
				<Route exact path="/admin/master/kecamatan" component={Kecamatan} />
				<Route exact path="/admin/master/kelurahan_desa" component={Kelurahan} />
				<Route exact path="/admin/master/pasar" component={Pasar} />
				<Route exact path="/admin/master/kategori_barang" component={KategoriBarang} />
				<Route exact path="/admin/master/satuan" component={Satuan} />
				<Route exact path="/admin/master/sub_komoditi" component={SubKomoditi} />
				<Route exact path="/admin/master/komoditi" component={Komoditi} />

				<Route exact path="/admin/perdagangan" component={Perdagangan} />
				<Route exact path="/admin/koperasi" component={Koperasi} />
				<Route exact path="/admin/umkm" component={Umkm} />
				<Route exact path="/admin/maps" component={Maps} />

				<Route exact path="/perdagangan" component={PerdaganganUser} />
				<Route exact path="/koperasi" component={KoperasiUser} />
				<Route exact path="/umkm" component={UMKMUser} />
				<Route exact path="/stats" component={StatsUser} />
				<Route exact path="/maps" component={MapsUser} />

				{/* <Route exact path="/addons" component={AddonsNavPage} />
				<Route exact path="/advanced" component={AdvancedNavPage} />
				<Route exact path="/components" component={ComponentsNavPage} />
				<Route exact path="/css" component={CSSNavPage} />
				<Route exact path="/forms" component={FormsNavPage} />
				<Route exact path="/modals" component={ModalsNavPage} />
				<Route exact path="/navigation" component={NavigationNavPage} />
				<Route exact path="/tables" component={TablesNavPage} /> */}

				{/* FREE */}
				{/* <Route path="/addons/iframe" component={IframePage} />
				<Route path="/addons/edge-header" component={EdgeHeaderPage} />
				<Route path="/addons/notifications" component={NotificationPage} />
				<Route path="/addons/treeview" component={TreeviewPage} />
				<Route path="/addons/Rating" component={RatingPage} />
				<Route path="/advanced/carousel" component={CarouselPage} />
				<Route path="/advanced/collapse" component={CollapsePage} />
				<Route path="/advanced/videocarousel" component={VideoCarouselPage} />
				<Route path="/advanced/alerts" component={AlertPage} />
				<Route path="/advanced/popover" component={PopoverPage} />
				<Route path="/advanced/tooltips" component={TooltipsPage} />
				<Route path="/advanced/charts" component={ChartsPage} />
				<Route path="/advanced/scrollbar" component={ScrollBarPage} />
				<Route path="/css/animations" component={AnimationPage} />
				<Route path="/css/icons" component={FaPage} />
				<Route path="/css/jumbotron" component={JumbotronPage} />
				<Route path="/css/masks" component={MasksPage} />
				<Route path="/css/hover" component={HoverPage} />
				<Route path="/css/masonry" component={MasonryPage} />
				<Route path="/components/media" component={MediaPage} />
				<Route path="/components/badge" component={BadgePage} />
				<Route path="/components/cards" component={CardsPage} />
				<Route path="/components/buttons" component={ButtonPage} />
				<Route path="/components/dropdown" component={DropdownPage} />
				<Route path="/components/progress" component={ProgressPage} />
				<Route path="/components/pagination" component={PaginationPage} />
				<Route path="/components/list-group" component={ListGroupPage} />
				<Route path="/components/panels" component={PanelPage} />
				<Route path="/components/search" component={SearchPage} />
				<Route path="/components/spinner" component={SpinnerPage} />
				<Route path="/components/tabs" component={TabsPage} />
				<Route path="/components/pills" component={PillsPage} />
				<Route path="/forms/forms" component={FormsPage} />
				<Route path="/forms/validation" component={ValidationPage} />
				<Route path="/forms/input" component={InputPage} />
				<Route path="/forms/inputgroup" component={InputGroupPage} />
				<Route path="/modals/modal" component={ModalPage} />
				<Route path="/modals/modal-form" component={ModalFormPage} />
				<Route path="/modals/modal-examples" component={ModalExamplesPage} />
				<Route path="/navigation/navbar" component={NavbarPage} />
				<Route path="/navigation/breadcrumb" component={BreadcrumbPage} />
				<Route path="/navigation/navs" component={NavsPage} />
				<Route path="/navigation/footer" component={FooterPage} />
				<Route path="/tables/table" component={TablePage} />
				<Route path="/tables/table-responsive" component={TableResponsivePage} />
				<Route path="/tables/table-scroll" component={TableScrollPage} />
				<Route path="/tables/table-styles" component={TableStylesPage} />
				<Route path="/tables/datatable-api" component={DatatableApiPage} />
				<Route path="/tables/datatable" component={DatatablePage} /> */}

				<Route
					render={function () {
						return (
							<>
								<Navbar />
								<h1 className="text-center">Halaman belum Ada</h1>
							</>
						);
					}}
				/>
			</Switch>
		);
	}
}

export default Routes;
