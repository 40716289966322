import React from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import Api from '../../../../services/Api';
import { REST_KEY } from '../../../../config';
import EditKategoriBarang from './EditKategoriBarang';
import TambahKategoriBarang from './TambahKategoriBarang';
import HapusKategoriBarang from './HapusKategoriBarang';

class KategoriBarang extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
			token_api: REST_KEY,
		};
	}

	componentDidMount = async () => {
		await Api.post('master/listkategoribarang', this.state).then((response) => {
			// this.setState({
			// 	komoditi: response.data.komoditi,
			// 	pasar: response.data.pasar,
			// });
			let data = response.data;
			let { rows } = response.data;
			let columns = [
				{
					label: 'Action',
					field: 'action',
					width: 100,
				},
				{
					label: 'Nama',
					field: 'namaKategori',
					width: 100,
				},
				{
					label: 'Deskripsi',
					field: 'deskKategori',
					width: 100,
				},
			];

			rows = rows.map((row) => ({
				...row,
				action: (
					<MDBRow className="ml-1">
						<EditKategoriBarang
							idKategori={row.idKategori}
							namaKategori={row.namaKategori}
							deskKategori={row.deskKategori}
							refresh={this.componentDidMount}
						/>
						<HapusKategoriBarang
							idKategori={row.idKategori}
							namaKategori={row.namaKategori}
							refresh={this.componentDidMount}
						/>
					</MDBRow>
				),
			}));

			data = {
				columns,
				rows,
			};
			this.setState({ data, loading: false });
		});
	};

	render() {
		const { data } = this.state;
		const loading = this.state.loading;
		return (
			<MDBContainer className="mt-3" fluid>
				<TambahKategoriBarang refresh={this.componentDidMount} />
				<MDBRow className="py-3">
					<MDBCol md="12">
						<MDBCard>
							<MDBCardBody>
								<h5 className="text-center">Daftar Kategori Barang</h5>
								<MDBDataTable striped bordered hover scrollX data={data} sortRows={['id']} />
								{loading && (
									<div className="text-center">
										<p>Loading....</p>
										<div className="spinner-border text-primary" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-success" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-warning" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-info" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default KategoriBarang;

//import NumberFormat from 'react-number-format';
/* <NumberFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */
