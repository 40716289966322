import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBInput, MDBCol } from 'mdbreact';
import Api from '../../../services/Api';
import { store } from 'react-notifications-component';
import { REST_KEY } from '../../../config';

class DelUmkm extends Component {
	state = {
		modalDel: false,
		idUMKM: this.props.idUMKM,
		namaUMKM: this.props.namaUMKM,
		token_api: REST_KEY,
	};

	toggle = () => {
		this.setState({
			modalDel: !this.state.modalDel,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('umkm/del', this.state);
		this.setState({
			modalDel: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data ' + this.props.namaUMKM + ' berhasil di Hapus',
			type: 'warning',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};
	render() {
		return (
			<>
				<button className="btn btn-danger btn-small px-2" color="danger" onClick={this.toggle}>
					<i className="fa fa-trash" aria-hidden="true"></i>
				</button>
				<MDBModal isOpen={this.state.modalDel} toggle={this.toggle} fullHeight position="right">
					<MDBModalHeader toggle={this.toggle}>
						Apakah Anda Akan Menghapus Data <span className="text-danger">{this.props.namaUMKM} ?</span>
					</MDBModalHeader>
					<MDBModalBody>
						<MDBCol>
							<form onSubmit={this.handlerSubmit}>
								<div className="grey-text text-left">
									<MDBInput name="idUser" value={this.state.idUMKM} hidden />
								</div>
								<div className="text-center">
									<MDBBtn color="primary" type="submit">
										Iya
									</MDBBtn>
									<MDBBtn color="danger" onClick={this.toggle}>
										Batal
									</MDBBtn>
								</div>
							</form>
						</MDBCol>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default DelUmkm;
