import React, { Component } from 'react';
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBInput,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBBadge,
} from 'mdbreact';
import Api from '../../../../services/Api';
import { REST_KEY } from '../../../../config';
import { store } from 'react-notifications-component';
import Select from 'react-select';

class TambahSubKomoditi extends Component {
	state = {
		modalTambah: false,
		token_api: REST_KEY,
	};

	toggle = () => {
		this.setState({
			modalTambah: !this.state.modalTambah,
			namaSubKomoditi: '',
			deskSubKomoditi: '',
			kategori: this.props.kategori,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	selectChange = (selectedOption) => {
		this.setState({ [selectedOption.name]: selectedOption.value });
	};

	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('master/insertsubkomoditi', this.state);
		this.setState({
			modalTambah: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data ' + this.state.namaKelurahanDesa + ' berhasil di Tambahkan',
			type: 'success',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};

	render() {
		return (
			<>
				<button className="btn btn-success btn-small px-2" onClick={this.toggle}>
					<i className="fa fa-plus" aria-hidden="true"></i> Tambah Data
				</button>
				<MDBModal
					isOpen={this.state.modalTambah}
					toggle={this.toggle}
					className="mw-100"
					fullHeight
					position="top"
				>
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Tambah Data <span className="text-success">{this.props.namaKoperasi}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<MDBRow>
										<MDBCol md="4">
											<MDBBadge color="primary">Pilih Kategori</MDBBadge>
											<Select
												options={this.state.kategori}
												placeholder="Pilih Kategori"
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="Nama Sub Komoditi"
												name="namaSubKomoditi"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaKelurahanDesa}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="Deskripsi Sub Komoditi"
												name="deskSubKomoditi"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.deskSubKomoditi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default TambahSubKomoditi;
