import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBInput, MDBCol, MDBBadge } from 'mdbreact';
import Api from '../../../services/Api';
import { store } from 'react-notifications-component';

class EditUser extends Component {
	state = {
		modal: false,
		idUser: this.props.idUser,
		namaUser: this.props.namaUser,
		usernameUser: this.props.usernameUser,
		emailUser: this.props.emailUser,
		password: this.props.password,
		role: this.props.role,
		role2: sessionStorage.role,
		token_api: 'defaultrestkey',
	};

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.put('userapi/update', this.state);
		this.setState({
			modal: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data ' + this.props.namaUser + ' berhasil di Edit',
			type: 'success',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};
	render() {
		let dis = '';
		if (this.state.role2 !== '1') {
			dis = true;
		}
		return (
			<>
				<MDBBtn className="btn-floating btn-lg btn-unique text-success" onClick={this.toggle}>
					<i className="fas fa-edit p-3"></i>
				</MDBBtn>

				<MDBModal isOpen={this.state.modal} toggle={this.toggle} fullHeight position="right">
					<MDBModalHeader toggle={this.toggle}>
						Edit Data <span className="text-danger">{this.props.namaUser}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<MDBCol>
							<form onSubmit={this.handlerSubmit}>
								<div className="grey-text text-left">
									<MDBInput
										name="idUser"
										value={this.state.idUser}
										onChange={this.handlerChange}
										hidden
									/>
									<MDBInput
										label="Nama Lengkap"
										name="namaUser"
										icon="user"
										group
										type="text"
										required
										validate
										error="wrong"
										success="right"
										value={this.state.namaUser}
										onChange={this.handlerChange}
									/>
									<MDBInput
										name="usernameUser"
										label="Username/Nama Pengguna"
										icon="user"
										group
										type="text"
										required
										validate
										error="wrong"
										success="right"
										value={this.state.usernameUser}
										onChange={this.handlerChange}
									/>
									<MDBInput
										label="Email User"
										icon="envelope"
										group
										name="emailUser"
										type="email"
										required
										validate
										error="wrong"
										success="right"
										value={this.state.emailUser}
										onChange={this.handlerChange}
									/>
									<MDBInput
										name="password"
										label="Your password"
										icon="lock"
										group
										type="password"
										validate
										value={this.state.password}
										onChange={this.handlerChange}
									/>
									<div className="mb-4">
										<MDBBadge pill color="light">
											Status
										</MDBBadge>
										<select
											className="browser-default custom-select"
											name="role"
											value={this.state.role}
											onChange={this.handlerChange}
											disabled={dis}
										>
											<option value="4">Admin UMKM</option>
											<option value="3">Admin Koperasi</option>
											<option value="2">Admin Perdagangan</option>
											<option value="1">Super Admin</option>
											<option value="0">Non Aktif</option>
										</select>
									</div>
								</div>
								<div className="text-center">
									<MDBBtn color="primary" type="submit">
										Kirim
									</MDBBtn>
								</div>
							</form>
						</MDBCol>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default EditUser;
