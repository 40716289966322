let WS_URL = null;
if (process.env.NODE_ENV === 'development') {
	WS_URL = 'http://localhost/sidasi/webservices/'; //WEBSERVICE URL
} else {
	WS_URL = 'https://sidasi.kotamobagukota.go.id/webservices/'; //WEBSERVICE URL
}

let PDUMKM = 'umkm/printumkm/';
let PDK = 'koperasi/printkoperasi/'; //PRINT DAFTAR KOPERASI
let REST_NAME = 'token_api:defaultrestkey';
let REST_KEY = 'defaultrestkey'; //key rest api

export { WS_URL, PDK, REST_KEY, REST_NAME, PDUMKM };
