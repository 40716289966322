import React, { Component } from 'react';
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBInput,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBIcon,
} from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';
import { store } from 'react-notifications-component';

class TambahUmkm extends Component {
	state = {
		modal: false,
		idUMKM: '',
		namaUMKM: '',
		namaPemilik: '',
		alamat: '',
		desaKelurahan: '',
		kecamatan: '',
		kabupatenKota: '',
		NIB: '',
		SIUP_IUMK: '',
		NPWP: '',
		tahunBerdiri: '',
		jenisProdukJasa1: '',
		jeiniProdukJasa2: '',
		jenisProdukJasa3: '',
		jenisProdukJasa4: '',
		jenisProdukJasa5: '',
		sertifikasiPIRT: '',
		sertifikasiBPOM: '',
		sertifikasiHalal: '',
		sertifikasiHakMerk: '',
		sertifikasiHakCipta: '',
		HP1: '',
		HP2: '',
		email: '',
		website: '',
		facebook: '',
		instagram: '',
		eCommerce1: '',
		eCommerce2: '',
		eCommerce3: '',
		eCommerce4: '',
		eCommerce5: '',
		asset: '0',
		omsetPerbulan: '0',
		tenagaKerjaLaki: '0',
		tenagaKerjaPerempuan: '0',
		wilayahPemasaranDesa: '',
		wilayahPemasaranKabKota: '',
		wilayahPemasaranProv: '',
		wilayahPemasaranNasional: '',
		wilayahPemasaranExport: '',
		kapasitasProdukPerhari: '',
		permasalahan: '',
		saranSolusi: '',
		tanggal: '',
		token_api: REST_KEY,
	};

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('umkm/insert', this.state);
		this.setState({
			modal: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data ' + this.state.namaUMKM + ' berhasil di Tambahkan',
			type: 'success',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};
	render() {
		return (
			<>
				<button className="btn btn-success btn-small px-2" onClick={this.toggle}>
					<i className="fa fa-plus" aria-hidden="true"></i> Tambah Data UMKM
				</button>

				<MDBModal isOpen={this.state.modal} toggle={this.toggle} className="mw-100" position="bottom">
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Tambah Data UMKM<span className="text-success">{this.props.namaUMKM}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<h6 className="text-center text-primary font-italic">Form Dasar</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBInput
											name="idKoperasi"
											value={this.state.idUMKM}
											onChange={this.handlerChange}
											hidden
										/>
										<MDBCol md="6">
											<MDBInput
												label="Nama UMKM"
												name="namaUMKM"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaUMKM}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="namaPemilik"
												label="Nama Pemilik"
												icon="user-tie"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaPemilik}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Alamat UMKM</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="alamat"
												label="Alamat"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.alamat}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="desaKelurahan"
												label="Desa Kelurahan"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.desaKelurahan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="kecamatan"
												label="Kecamatan"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.kecamatan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="kabupatenKota"
												label="Kabupaten/Kota"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.kabupatenKota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Nomor Pokok WajiB Pajak (NPWP), SIUP/IUMK, Tahun Berdiri
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="NIB"
												label="NIB"
												icon="credit-card"
												group
												type="text"
												validate
												value={this.state.NIB}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="SIUP_IUMK"
												label="SIUP/IUMK"
												icon="credit-card"
												group
												type="text"
												validate
												value={this.state.SIUP_IUMK}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="NPWP"
												label="NPWP"
												icon="credit-card"
												group
												type="text"
												validate
												value={this.state.NPWP}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="tahunBerdiri"
												label="Tahun Berdiri"
												icon="calendar-week"
												group
												type="number"
												min={1900}
												max={3000}
												validate
												value={this.state.tahunBerdiri}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Produk Usaha</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa1"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa1}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa2"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa2}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa3"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa3}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa4"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa4}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa5"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa5}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">No. Sertifikasi</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiPIRT"
												label="P.IRT"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiPIRT}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiBPOM"
												label="BPOM"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiBPOM}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiHalal"
												label="Halal"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiHalal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiHakMerk"
												label="Hak Merk"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiHakMerk}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiHakCipta"
												label="Hak Cipta"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiHakCipta}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Telp/HP & Media Social
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fab icon="whatsapp" />
													</span>
												</div>
												<input
													type="text"
													name="HP1"
													className="form-control"
													placeholder="HP/Whatsapp"
													aria-label="HP/Whatsapp"
													aria-describedby="basic-addon"
													value={this.state.HP1}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fab icon="whatsapp" />
													</span>
												</div>
												<input
													type="text"
													name="HP2"
													className="form-control"
													placeholder="HP/Whatsapp"
													aria-label="HP/Whatsapp"
													aria-describedby="basic-addon"
													value={this.state.HP2}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fas icon="at" />
													</span>
												</div>
												<input
													type="text"
													name="email"
													className="form-control"
													placeholder="Email"
													aria-label="Email"
													aria-describedby="basic-addon"
													value={this.state.email}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fas icon="globe" />
													</span>
												</div>
												<input
													type="text"
													name="website"
													className="form-control"
													placeholder="Website"
													aria-label="Website"
													aria-describedby="basic-addon"
													value={this.state.website}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<i className="fab fa-facebook prefix"></i>
													</span>
												</div>
												<input
													type="text"
													name="facebook"
													className="form-control"
													placeholder="Facebook"
													aria-label="Facebook"
													aria-describedby="basic-addon"
													value={this.state.facebook}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fab icon="instagram" />
													</span>
												</div>
												<input
													type="text"
													name="instagram"
													className="form-control"
													placeholder="Instagram"
													aria-label="Instagram"
													aria-describedby="basic-addon"
													value={this.state.instagram}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Platform E-Commerce Penjualan
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce1"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce1}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce2"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce2}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce3"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce3}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce4"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce4}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce5"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce5}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Asset, Omset, Jumlah Tenaga Kerja dan Kapasitas Produk Dalam Sehari
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="4">
											<MDBInput
												name="tenagaKerjaLaki"
												label="Tenaga Kerja Laki-Laki"
												icon="mars-double"
												group
												type="number"
												min={0}
												max={300000}
												validate
												value={this.state.tenagaKerjaLaki}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="tenagaKerjaPerempuan"
												label="Tenaga Kerja Perempuan"
												icon="venus-double"
												group
												type="number"
												min={0}
												max={300000}
												validate
												value={this.state.tenagaKerjaPerempuan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="Total Tenaga Kerja"
												icon="equals"
												name=""
												group
												type="number"
												validate
												disabled
												value={
													parseInt(this.state.tenagaKerjaLaki) +
													parseInt(this.state.tenagaKerjaPerempuan)
												}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="asset"
												label="Asset (diluar tanah dan bangunan)"
												icon="home"
												group
												type="number"
												validate
												value={this.state.asset}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="omsetPerbulan"
												label="Omset Perbulan"
												icon="file-invoice-dollar"
												group
												type="number"
												validate
												value={this.state.omsetPerbulan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="kapasitasProdukPerhari"
												label="Kapasitas Produk Perhari"
												icon="warehouse"
												group
												type="number"
												validate
												value={this.state.kapasitasProdukPerhari}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Wilayah Pemasaran</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranDesa"
												label="Desa/Kelurahan: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranDesa}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranKabKota"
												label="Kab/Kota: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranKabKota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranProv"
												label="Provinsi: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranProv}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranNasional"
												label="Nasional: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranNasional}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranExport"
												label="Export: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranExport}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Permasalahan dan Solusi
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="6">
											<MDBInput
												name="permasalahan"
												label="Permasalahan yang dihadapi"
												icon="dizzy"
												group
												type="text"
												validate
												value={this.state.permasalahan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="saranSolusi"
												label="Saran dan Solusi"
												icon="check-square"
												group
												type="text"
												validate
												value={this.state.saranSolusi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Lokasi Google Maps</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="12" className="text-center">
											<MDBBtn
												href="https://www.openstreetmap.org/search?query=kotamobagu#map=14/0.7283/124.3065"
												target="_blank"
												gradient="aqua"
											>
												<MDBIcon icon="map-marked-alt fa-2x" />
											</MDBBtn>
										</MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="latitude"
												label="Latitude"
												icon="map-marked"
												group
												type="number"
												validate
												value={this.state.latitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="longitude"
												label="Longitude"
												icon="map-marked-alt"
												group
												type="number"
												validate
												value={this.state.longitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Petugas Pendata</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="petugasPendata"
												label="Nama:"
												icon="file-signature"
												group
												type="text"
												validate
												value={this.state.petugasPendata}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="tanggal"
												label="Didata pada tanggal:"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.tanggal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default TambahUmkm;
