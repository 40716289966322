import React, { Component } from 'react';
import {
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavbarToggler,
	MDBCollapse,
	MDBNavItem,
	MDBNavLink,
} from 'mdbreact';
import './css.css';
//import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Redirect } from 'react-router-dom';
import Keluar from './Keluar';

export default class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseID: '',
			loggedIn: false,
			data: '',
		};
	}
	UNSAFE_componentWillMount() {
		let getRole = sessionStorage.role;
		let getNama = sessionStorage.namaUser;
		if (getRole || getNama) {
			this.setState({
				role: getRole,
				loggedIn: true,
				namaLogin: getNama,
			});
		}
	}

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : '',
		}));

	closeCollapse = (collID) => () => {
		const { collapseID } = this.state;
		window.scrollTo(0, 0);
		collapseID === collID && this.setState({ collapseID: '' });
	};
	render() {
		const overlay = (
			<div
				id="sidenav-overlay"
				style={{ backgroundColor: 'transparent' }}
				onClick={this.toggleCollapse('mainNavbarCollapse')}
			/>
		);
		if (this.state.loggedIn === false) {
			return <Redirect to="/" />;
		}
		const { collapseID } = this.state;
		let hakNav = '';
		if (this.state.role === '1') {
			hakNav = (
				<>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/master">
							<strong>Referensi</strong>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/perdagangan">
							<strong>Perdagangan</strong>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/koperasi">
							<strong>Koperasi</strong>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/umkm">
							<strong>UMKM</strong>
						</MDBNavLink>
					</MDBNavItem>
				</>
			);
		}
		if (this.state.role === '2') {
			hakNav = (
				<>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/master">
							<strong>Referensi</strong>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/perdagangan">
							<strong>Perdagangan</strong>
						</MDBNavLink>
					</MDBNavItem>
				</>
			);
		}
		if (this.state.role === '3') {
			hakNav = (
				<>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/koperasi">
							<strong>Koperasi</strong>
						</MDBNavLink>
					</MDBNavItem>
				</>
			);
		}
		if (this.state.role === '4') {
			hakNav = (
				<>
					<MDBNavItem>
						<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/umkm">
							<strong>UKM</strong>
						</MDBNavLink>
					</MDBNavItem>
				</>
			);
		}
		return (
			<div>
				<MDBNavbar color="indigo" dark expand="md" fixed="top" scrolling>
					<MDBNavbarBrand href="/">
						<div className="nav-brand">
							<div>
								{/* <Logo style={{ height: '2.5rem', width: '2.5rem' }} /> */}
								<img
									src={require('../../assets/ktg.png')}
									alt="mdbreact-logo"
									style={{ height: '2.5rem', width: '2.5rem' }}
								/>
							</div>
							<div>
								<strong>SIDASI ADMIN PAGE</strong>
								<div className="nav-nama">
									Welcome Back <strong>{this.state.namaLogin}</strong>
								</div>
							</div>
						</div>
					</MDBNavbarBrand>
					<MDBNavbarToggler onClick={this.toggleCollapse('mainNavbarCollapse')} />
					<MDBCollapse id="mainNavbarCollapse" isOpen={collapseID} navbar>
						<MDBNavbarNav right>
							<MDBNavItem>
								<MDBNavLink
									exact
									to="/admin/dashboard"
									onClick={this.closeCollapse('mainNavbarCollapse')}
								>
									<strong>Dashboard</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink
									onClick={this.closeCollapse('mainNavbarCollapse')}
									to="/admin/manajemenuser"
								>
									<strong>Users</strong>
								</MDBNavLink>
							</MDBNavItem>
							{hakNav}
							<MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/admin/maps">
									<strong>Maps</strong>
								</MDBNavLink>
							</MDBNavItem>
							<Keluar />
						</MDBNavbarNav>
					</MDBCollapse>
				</MDBNavbar>
				{collapseID && overlay}
			</div>
		);
	}
}
