import React from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import Api from '../../../../services/Api';
import { REST_KEY } from '../../../../config';
import EditSubKomoditi from './EditSubKomoditi';
import TambahKomoditi from './TambahSubKomoditi';
import HapusSubKomoditi from './HapusSubKomoditi';

class Kelurahan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
			token_api: REST_KEY,
		};
	}

	componentDidMount = async () => {
		await Api.post('master/listsubkomoditi', this.state).then((response) => {
			this.setState({
				kategori: response.data.kategori,
			});
			let data = response.data;
			let { rows } = response.data;
			let columns = [
				{
					label: 'Action',
					field: 'action',
					width: 75,
				},
				{
					label: 'Nama Sub Komoditi',
					field: 'namaSubKomoditi',
					width: 100,
				},
				{
					label: 'Nama Kategori',
					field: 'namaKategori',
					width: 120,
				},
				{
					label: 'Deskripsi',
					field: 'deskSubKomoditi',
					width: 100,
				},
			];

			rows = rows.map((row) => ({
				...row,
				action: (
					<MDBRow className="ml-1">
						<EditSubKomoditi
							idSubKomoditi={row.idSubKomoditi}
							idKategori={row.idKategori}
							namaSubKomoditi={row.namaSubKomoditi}
							deskSubKomoditi={row.deskSubKomoditi}
							namaKategori={row.namaKategori}
							kategori={this.state.kategori}
							refresh={this.componentDidMount}
						/>
						<HapusSubKomoditi
							idSubKomoditi={row.idSubKomoditi}
							namaSubKomoditi={row.namaSubKomoditi}
							refresh={this.componentDidMount}
						/>
					</MDBRow>
				),
			}));

			data = {
				columns,
				rows,
			};
			this.setState({ data, loading: false });
		});
	};

	render() {
		const { data } = this.state;
		const loading = this.state.loading;
		return (
			<MDBContainer className="mt-3" fluid>
				<TambahKomoditi refresh={this.componentDidMount} kategori={this.state.kategori} />
				<MDBRow className="py-3">
					<MDBCol md="12">
						<MDBCard>
							<MDBCardBody>
								<h5 className="text-center">Daftar Sub Komoditi</h5>
								<MDBDataTable scrollX striped bordered data={data} sortRows={['id']} />
								{loading && (
									<div className="text-center">
										<p>Loading....</p>
										<div className="spinner-border text-primary" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-success" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-warning" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-info" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default Kelurahan;

//import NumberFormat from 'react-number-format';
/* <NumberFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */
