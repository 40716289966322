import React from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import SideNavs from '../../../components/AdminPage/SideNav';
import Api from '../../../services/Api';
import EditKoperasi from './EditKoperasi';
import DelKoperasi from './DelKoperasi';
import TambahKoperasi from './TambahKoperasi';
import PrintKoperasi from './PrintKoperasi';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

class Koperasi extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
		};
	}

	componentDidMount = async () => {
		let params = {
			token_api: 'defaultrestkey',
		};
		await Api.post('koperasi/list', params).then((response) => {
			let data = response.data;
			let { rows } = response.data;
			let columns = [
				{
					label: 'Action',
					field: 'action',
					width: 120,
				},
				{
					label: 'Nama Koperasi',
					field: 'namaKoperasi',
					sort: 'asc',
					width: 100,
				},
				{
					label: 'Status Koperasi',
					field: 'statusKoperasi',
					sort: 'asc',
					width: 100,
				},
				{
					label: 'NIK Koperasi',
					field: 'NIKKoperasi',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Alamat',
					field: 'alamat',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Desa/Kelurahan',
					field: 'desaKelurahan',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Kab/Kota',
					field: 'kabupatenKota',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Nama Manager',
					field: 'namaManager',
					sort: 'asc',
					width: 150,
				},
			];

			rows = rows.map((row) => ({
				...row,
				action: (
					<MDBRow className="ml-1">
						<EditKoperasi
							idKoperasi={row.idKoperasi}
							jumlahKoperasi={row.jumlahKoperasi}
							statusKoperasi={row.statusKoperasi}
							namaKoperasi={row.namaKoperasi}
							NIKKoperasi={row.NIKKoperasi}
							noSertifikatKoperasi={row.noSertifikatKoperasi}
							noAktaPendirian={row.noAktaPendirian}
							tanggalAktaPendirian={row.tanggalAktaPendirian}
							noPAD={row.noPAD}
							tanggalPAD={row.tanggalPAD}
							noPengesahanAktaPendirian={row.noPengesahanAktaPendirian}
							tanggalPengesahanAktaPendirian={row.tanggalPengesahanAktaPendirian}
							noPengesahanAktaPAD={row.noPengesahanAktaPAD}
							tanggalPengesahanAktaPAD={row.tanggalPengesahanAktaPAD}
							alamat={row.alamat}
							desaKelurahan={row.desaKelurahan}
							kecamatan={row.kecamatan}
							kabupatenKota={row.kabupatenKota}
							namaPengurusKetua={row.namaPengurusKetua}
							hpPengurusKetua={row.hpPengurusKetua}
							namaPengurusSekretaris={row.namaPengurusSekretaris}
							hpPengurusSekretaris={row.hpPengurusSekretaris}
							namaPengurusBendahara={row.namaPengurusBendahara}
							hpPengurusBendahara={row.hpPengurusBendahara}
							namaPengawasKetua={row.namaPengawasKetua}
							hpPengawasKetua={row.hpPengawasKetua}
							namaPengawasSekretaris={row.namaPengawasSekretaris}
							hpPengawasSekretaris={row.hpPengawasSekretaris}
							namaPengawasAnggota={row.namaPengawasAnggota}
							hpPengawasAnggota={row.hpPengawasAnggota}
							masaBaktiTahun={row.masaBaktiTahun}
							masaBaktiAwal={row.masaBaktiAwal}
							masaBaktiAkhir={row.masaBaktiAkhir}
							npwp={row.npwp}
							bentukKoperasi={row.bentukKoperasi}
							jenisKoperasi={row.jenisKoperasi}
							kegiatanJenisUsaha={row.kegiatanJenisUsaha}
							kegiatanUsahaAktif={row.kegiatanUsahaAktif}
							namaManager={row.namaManager}
							jumlahKaryawanLaki={row.jumlahKaryawanLaki}
							jumlahKaryawanPerempuan={row.jumlahKaryawanPerempuan}
							jumlahAnggotaPenuhLaki={row.jumlahAnggotaPenuhLaki}
							jumlahAnggotaPenuhPerempuan={row.jumlahAnggotaPenuhPerempuan}
							JumlahCalonAnggota={row.JumlahCalonAnggota}
							pelaksanaanRAT1Tahun={row.pelaksanaanRAT1Tahun}
							pelaksanaanRAT1Tanggal={row.pelaksanaanRAT1Tanggal}
							pelaksanaanRAT2Tahun={row.pelaksanaanRAT2Tahun}
							pelaksanaanRAT2Tanggal={row.pelaksanaanRAT2Tanggal}
							pelaksanaanRAT3Tahun={row.pelaksanaanRAT3Tahun}
							pelaksanaanRAT3Tanggal={row.pelaksanaanRAT3Tanggal}
							indikatorUsahaModalSendiri={row.indikatorUsahaModalSendiri}
							indikatorUsahaModalLuar={row.indikatorUsahaModalLuar}
							indikatorUsahaAsset={row.indikatorUsahaAsset}
							indikatorUsahaVolume={row.indikatorUsahaVolume}
							indikatorSHU={row.indikatorSHU}
							longitude={row.longitude}
							latitude={row.latitude}
							petugasPendata={row.petugasPendata}
							refresh={this.componentDidMount}
						/>
						<DelKoperasi
							namaKoperasi={row.namaKoperasi}
							idKoperasi={row.idKoperasi}
							refresh={this.componentDidMount}
						/>
						<PrintKoperasi idKoperasi={row.idKoperasi} />
					</MDBRow>
				),
			}));
			data = {
				columns,
				rows,
			};
			this.setState({ data, loading: false });
		});
	};

	render() {
		const { data } = this.state;
		const loading = this.state.loading;
		return (
			<MDBContainer className="mt-3" fluid>
				<ReactNotification />
				<TambahKoperasi refresh={this.componentDidMount} />
				<SideNavs />
				<MDBRow className="py-3">
					<MDBCol md="12">
						<MDBCard>
							<MDBCardBody>
								<h3 className="text-center">Daftar Koperasi</h3>
								<MDBDataTable striped bordered hover scrollX data={data} sortRows={['id']} />
								{loading && (
									<div className="text-center">
										<p>Loading....</p>
										<div className="spinner-border text-primary" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-success" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-warning" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-info" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default Koperasi;

//import NumberFormat from 'react-number-format';
/* <NumberFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */
