import React, { Component } from 'react';
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBInput,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBBadge,
} from 'mdbreact';
import Select from 'react-select';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';
import { store } from 'react-notifications-component';

class EditPerdagangan extends Component {
	state = {
		modalEdit: false,
		token_api: REST_KEY,
	};

	toggle = () => {
		this.setState({
			modalEdit: !this.state.modalEdit,
			idPerdagangan: this.props.idPerdagangan,
			idPasar: this.props.idPasar,
			idKomoditi: this.props.idKomoditi,
			komoditi: this.props.komoditi,
			pasar: this.props.pasar,
			minggu: this.props.minggu,
			harga: this.props.harga,
			tanggal: this.props.tanggal,
			namaPasar: this.props.namaPasar,
			namaKomoditi: this.props.namaKomoditi,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	selectChange = (selectedOption) => {
		this.setState({ [selectedOption.name]: selectedOption.value });
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('perdagangan/update', this.state).then((response) => {
			let status = response.data;
			this.setState({ message: status.message, title: status.title, type: status.type });
		});
		this.setState({
			modalEdit: false,
		});
		store.addNotification({
			title: this.state.title,
			message: this.state.message,
			type: this.state.type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};

	render() {
		return (
			<>
				<button className="btn btn-primary btn-small px-2" href="#" onClick={this.toggle}>
					<i className="fa fa-edit" aria-hidden="true"></i>
				</button>
				<MDBModal
					isOpen={this.state.modalEdit}
					toggle={this.toggle}
					className="mw-100"
					fullHeight
					position="right"
				>
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Edit Data <span className="text-success">{this.props.namaKomoditi}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<MDBRow>
										<MDBCol md="12" className="mb-3">
											<MDBBadge color="primary">Pilih Komoditi</MDBBadge>
											<Select
												options={this.props.komoditi}
												placeholder={this.state.namaKomoditi}
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="12" className="mb-3">
											<MDBBadge color="primary">Pilih Pasar</MDBBadge>
											<Select
												options={this.props.pasar}
												placeholder={this.state.namaPasar}
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="12">
											<MDBInput
												label="Harga"
												name="harga"
												icon="landmark"
												group
												type="number"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.harga}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="Minggu Ke-"
												name="minggu"
												icon="landmark"
												group
												type="number"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.minggu}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="tanggal"
												name="tanggal"
												icon="landmark"
												group
												type="date"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.tanggal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default EditPerdagangan;
