import React, { Component } from 'react';
import {
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavbarToggler,
	MDBCollapse,
	MDBNavItem,
	MDBNavLink,
	//	MDBTooltip,
} from 'mdbreact';
//import { ReactComponent as Logo } from '../../assets/logo.svg';

export default class Navbar extends Component {
	state = {
		collapseID: '',
	};

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : '',
		}));

	closeCollapse = (collID) => () => {
		const { collapseID } = this.state;
		window.scrollTo(0, 0);
		collapseID === collID && this.setState({ collapseID: '' });
	};
	render() {
		const overlay = (
			<div
				id="sidenav-overlay"
				style={{ backgroundColor: 'transparent' }}
				onClick={this.toggleCollapse('mainNavbarCollapse')}
			/>
		);

		const { collapseID } = this.state;
		return (
			<div>
				<MDBNavbar color="indigo" dark expand="md" fixed="top">
					<MDBNavbarBrand href="/" className="py-0 font-weight-bold">
						{/* <Logo style={{ height: '2.5rem', width: '2.5rem' }} /> */}
						<img
							src={require('../../assets/ktg.png')}
							alt="mdbreact-logo"
							style={{ height: '2.5rem', width: '2.5rem' }}
						/>
						<strong className="align-middle">SIDASI</strong>
					</MDBNavbarBrand>
					<MDBNavbarToggler onClick={this.toggleCollapse('mainNavbarCollapse')} />
					<MDBCollapse id="mainNavbarCollapse" isOpen={collapseID} navbar>
						<MDBNavbarNav right>
							<MDBNavItem>
								<MDBNavLink exact to="/" onClick={this.closeCollapse('mainNavbarCollapse')}>
									<strong>Home</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink exact to="/perdagangan" onClick={this.closeCollapse('mainNavbarCollapse')}>
									<strong>Perdagangan</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink exact to="/koperasi" onClick={this.closeCollapse('mainNavbarCollapse')}>
									<strong>Koperasi</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink exact to="/umkm" onClick={this.closeCollapse('mainNavbarCollapse')}>
									<strong>UKM</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink exact to="/stats" onClick={this.closeCollapse('mainNavbarCollapse')}>
									<strong>Statistik</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink exact to="/maps" onClick={this.closeCollapse('mainNavbarCollapse')}>
									<strong>Maps</strong>
								</MDBNavLink>
							</MDBNavItem>
							{/* <MDBNavItem>
								<MDBNavLink exact to="/officialnews" onClick={this.closeCollapse('mainNavbarCollapse')}>
									<strong>Official News</strong>
								</MDBNavLink>
							</MDBNavItem> */}
							{/* <MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/css">
									<strong>Statistik</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/components">
									<strong>Map</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/advanced">
									<strong>About</strong>
								</MDBNavLink>
							</MDBNavItem> */}
							{/* <MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/navigation">
									<strong>Navigation</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/forms">
									<strong>Forms</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/tables">
									<strong>Tables</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/modals">
									<strong>Modals</strong>
								</MDBNavLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to="/addons">
									<strong>Addons</strong>
								</MDBNavLink>
							</MDBNavItem>

							<MDBNavItem>
								<MDBTooltip placement="bottom" domElement style={{ display: 'block' }}>
									<a
										className="nav-link Ripple-parent"
										href="https://mdbootstrap.com/products/react-ui-kit/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<strong>
											<MDBIcon far icon="gem" />
										</strong>
									</a>
									<span>PRO</span>
								</MDBTooltip>
							</MDBNavItem>
							<MDBNavItem>
								<MDBTooltip placement="bottom" domElement style={{ display: 'block' }}>
									<a
										className="nav-link Ripple-parent"
										href="https://mdbootstrap.com/docs/react/getting-started/download/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<strong>
											<MDBIcon icon="download" />
										</strong>
									</a>
									<span>FREE</span>
								</MDBTooltip>
							</MDBNavItem>
							<MDBNavItem className="mr-2">
								<MDBTooltip placement="bottom" domElement style={{ display: 'block' }}>
									<a
										className="nav-link Ripple-parent"
										href="https://mdbootstrap.com/support/cat/react/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<strong>
											<MDBIcon icon="question-circle" />
										</strong>
									</a>
									<span>SUPPORT</span>
								</MDBTooltip>
							</MDBNavItem> */}
						</MDBNavbarNav>
					</MDBCollapse>
				</MDBNavbar>
				{collapseID && overlay}
			</div>
		);
	}
}
