import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBInput, MDBCol } from 'mdbreact';
import Api from '../../../services/Api';
import { store } from 'react-notifications-component';

class AddUser extends Component {
	state = {
		modal9: false,
		namaUser: '',
		usernameUser: '',
		emailUser: '',
		password: '',
		role: '2',
		token_api: 'defaultrestkey',
	};

	toggle = (nr) => () => {
		let modalNumber = 'modal' + nr;
		this.setState({
			[modalNumber]: !this.state[modalNumber],
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('userapi/registration', this.state);
		this.setState({
			modal9: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data ' + this.state.namaUser + ' berhasil di Tambahkan',
			type: 'success',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};
	render() {
		return (
			<MDBContainer>
				<MDBBtn color="primary" onClick={this.toggle(9)}>
					Tambah User
				</MDBBtn>
				<MDBModal isOpen={this.state.modal9} toggle={this.toggle(9)} fullHeight position="right">
					<MDBModalHeader toggle={this.toggle(9)}>Tambah User</MDBModalHeader>
					<MDBModalBody>
						<MDBCol>
							<form onSubmit={this.handlerSubmit}>
								<div className="grey-text text-left">
									<MDBInput
										label="Nama Lengkap"
										name="namaUser"
										icon="user"
										group
										type="text"
										required
										validate
										error="wrong"
										success="right"
										onChange={this.handlerChange}
									/>
									<MDBInput
										name="usernameUser"
										label="Username/Nama Pengguna"
										icon="user"
										group
										type="text"
										required
										validate
										error="wrong"
										success="right"
										onChange={this.handlerChange}
									/>
									<MDBInput
										label="Email User"
										icon="envelope"
										group
										name="emailUser"
										type="email"
										required
										validate
										error="wrong"
										success="right"
										onChange={this.handlerChange}
									/>
									<MDBInput
										name="password"
										label="Your password"
										icon="lock"
										group
										type="password"
										validate
										required
										onChange={this.handlerChange}
									/>
									<div className="mb-4">
										<select
											className="browser-default custom-select"
											name="role"
											onChange={this.handlerChange}
										>
											<option value="0" disabled>
												Pilih Level Akses
											</option>
											<option value="4">Admin UMKM</option>
											<option value="3">Admin Koperasi</option>
											<option value="2">Admin Perdagangan</option>
											<option value="1">Super Admin</option>
											<option value="0">Non Aktif</option>
										</select>
									</div>
								</div>
								<div className="text-center">
									<MDBBtn color="primary" type="submit">
										Kirim
									</MDBBtn>
								</div>
							</form>
						</MDBCol>
					</MDBModalBody>
				</MDBModal>
			</MDBContainer>
		);
	}
}

export default AddUser;
