import React from 'react';
import { MDBEdgeHeader, MDBFreeBird, MDBCol, MDBRow, MDBCardBody } from 'mdbreact';
import './HomePage.css';
import BtnLinks from '../../components/HomePage/aLink';
import ModalLogin from '../../components/HomePage/ModalLogin';
import Navbar from '../../components/NavBar/Navbar';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

class HomePage extends React.Component {
	state = {
		collapseID: '',
	};
	scrollToTop = () => window.scrollTo(0, 0);
	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : '',
		}));

	closeCollapse = (collID) => () => {
		const { collapseID } = this.state;
		window.scrollTo(0, 0);
		collapseID === collID && this.setState({ collapseID: '' });
	};

	toggle = (nr) => () => {
		const modalNumber = `modal${nr}`;
		this.setState({
			...this.state,
			[modalNumber]: !this.state[modalNumber],
		});
	};

	render() {
		return (
			<div>
				<Navbar />
				<MDBEdgeHeader color="indigo darken-3" className="sectionPage" />
				<div className="mt-3 mb-5">
					<MDBFreeBird>
						<MDBRow>
							<MDBCol md="10" className="mx-auto float-none white z-depth-1 py-2 px-2">
								<MDBCardBody className="text-center">
									<h2 className="h2-responsive mb-4">
										<strong className="font-weight-bold">
											<img
												src={require('../../assets/ktg.png')}
												alt="mdbreact-logo"
												className="pr-2 img-resp"
											/>
											Dinas Perdagangan, Koperasi & UKM
										</strong>
									</h2>
									<MDBRow />
									<p>Selamat Datang</p>
									<hr></hr>
									<p className="pb-4">Sistem Informasi Perdagangan, Koperasi dan UKM.</p>
								</MDBCardBody>
								<MDBRow className="d-flex flex-row justify-content-center row">
									<BtnLinks to="/maps" title="Maps" icon="newspaper" />
									<ModalLogin />
									<ReactNotification />
									<BtnLinks to="/stats" title="Statistik" icon="stream" />
								</MDBRow>
							</MDBCol>
						</MDBRow>
					</MDBFreeBird>
				</div>
			</div>
		);
	}
}

export default HomePage;
