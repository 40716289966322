import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { MDBBtn } from 'mdbreact';
import Select from 'react-select';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';

export default class BarChart extends Component {
	state = {
		token_api: REST_KEY,
		idKomoditi: null,
	};
	componentDidMount = async () => {
		await Api.post('stats/barchart', this.state).then((response) => {
			this.setState({
				token_api: REST_KEY,
				judul: response.data.judul,
				harga: response.data.harga,
				tanggal: response.data.tanggal,
				komoditi: response.data.komoditi,
				pasar: response.data.pasar,
				loading: false,
			});
		});
	};
	selectChange = (e) => {
		this.setState({ [e.name]: e.value, loading: true });
	};

	render() {
		var dynamicColors = function () {
			var r = Math.floor(Math.random() * 255);
			var g = Math.floor(Math.random() * 255);
			var b = Math.floor(Math.random() * 255);
			return 'rgb(' + r + ',' + g + ',' + b + ')';
		};
		return (
			<>
				<div className="container-chart">
					<div className="select-chart m-1">
						<Select options={this.state.komoditi} placeholder="Komoditi" onChange={this.selectChange} />
					</div>
					<div>
						<MDBBtn gradient="peach" size="sm" className="mr-2" onClick={this.componentDidMount}>
							Lihat
						</MDBBtn>
					</div>
				</div>
				<Bar
					data={{
						labels: this.state.pasar,
						datasets: [
							{
								label: this.state.judul,
								data: this.state.harga,
								borderColor: [
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
									dynamicColors(),
								],
								borderWidth: 4,
							},
						],
					}}
					width={100}
					height={50}
					options={{
						responsive: true,
						label: false,
						title: {
							display: true,
							text: 'Perbandingan Harga Pasar pada Komoditi ' + this.state.judul,
						},
						scales: {
							yAxes: [
								{
									ticks: {
										beginAtZero: true,
									},
								},
							],
						},
					}}
				/>
			</>
		);
	}
}
