import React from 'react';
import { MDBContainer } from 'mdbreact';
import SideNavs from '../../../components/NavBar/Navbar';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './Maps.css';
import { Map, TileLayer } from 'react-leaflet';
import PenandaPasar from './PenandaPasar';
import PenandaKoperasi from './PenandaKoperasi';
import PenandaUMKM from './PenandaUMKM';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';

class Maps extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: false,
			zoom: 14,
			token_api: REST_KEY,
			kategori: '',
			pasar: [],
			koperasi: [],
			umkm: [],
		};
	}
	componentDidMount = async () => {
		await Api.post('maps/pasar', this.state).then((response) => {
			this.setState({
				pasar: response.data.rows,
				kategori: response.data.kategori,
			});
			this.setState({ loading: false });
		});
		await Api.post('maps/koperasi', this.state).then((response) => {
			this.setState({
				koperasi: response.data.rows,
			});
			this.setState({ loading: false });
		});
		await Api.post('maps/umkm', this.state).then((response) => {
			this.setState({
				umkm: response.data.rows,
			});
			this.setState({ loading: false });
		});
	};
	render() {
		const Center = [0.7284, 124.309];
		const Pasar = this.state.pasar.map((data, key) => {
			return (
				<PenandaPasar
					key={key}
					idPasar={data.id}
					nama={data.nama}
					lat={data.lat}
					lng={data.lng}
					foto={data.foto}
					message={data.message}
					kategori={this.state.kategori}
				/>
			);
		});
		const Koperasi = this.state.koperasi.map((kop, key) => {
			return (
				<PenandaKoperasi
					key={key}
					id={kop.id}
					nama={kop.nama}
					lat={kop.lat}
					lng={kop.lng}
					message={kop.message}
				/>
			);
		});
		const Umkm = this.state.umkm.map((umkm, key) => {
			return (
				<PenandaUMKM
					key={key}
					id={umkm.id}
					nama={umkm.nama}
					lat={umkm.lat}
					lng={umkm.lng}
					message={umkm.message}
				/>
			);
		});

		return (
			<MDBContainer className="mt-3 map" fluid>
				<ReactNotification />
				<SideNavs />
				<Map className="map" center={Center} zoom={this.state.zoom}>
					<TileLayer
						attribution='&amp;copy <a href="http:kotamobagukota.go.id">Pemerintah Kotamobagu</a> Support'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					{Pasar}
					{Koperasi}
					{Umkm}
				</Map>
			</MDBContainer>
		);
	}
}
export default Maps;
