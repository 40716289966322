import React, { Component } from 'react';
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBInput,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBIcon,
} from 'mdbreact';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';
import { store } from 'react-notifications-component';

class UpdateUmkm extends Component {
	state = {
		modal2: false,
		idUMKM: this.props.idUMKM,
		namaUMKM: this.props.namaUMKM,
		namaPemilik: this.props.namaPemilik,
		alamat: this.props.alamat,
		desaKelurahan: this.props.desaKelurahan,
		kecamatan: this.props.kecamatan,
		kabupatenKota: this.props.kabupatenKota,
		NIB: this.props.NIB,
		SIUP_IUMK: this.props.SIUP_IUMK,
		NPWP: this.props.NPWP,
		tahunBerdiri: this.props.tahunBerdiri,
		jenisProdukJasa1: this.props.jenisProdukJasa1,
		jenisProdukJasa2: this.props.jenisProdukJasa2,
		jenisProdukJasa3: this.props.jenisProdukJasa3,
		jenisProdukJasa4: this.props.jenisProdukJasa4,
		jenisProdukJasa5: this.props.jenisProdukJasa5,
		sertifikasiPIRT: this.props.sertifikasiPIRT,
		sertifikasiBPOM: this.props.sertifikasiBPOM,
		sertifikasiHalal: this.props.sertifikasiHalal,
		sertifikasiHakMerk: this.props.sertifikasiHakMerk,
		sertifikasiHakCipta: this.props.sertifikasiHakCipta,
		HP1: this.props.HP1,
		HP2: this.props.HP2,
		email: this.props.email,
		website: this.props.website,
		facebook: this.props.facebook,
		instagram: this.props.instagram,
		eCommerce1: this.props.eCommerce1,
		eCommerce2: this.props.eCommerce2,
		eCommerce3: this.props.eCommerce3,
		eCommerce4: this.props.eCommerce4,
		eCommerce5: this.props.eCommerce5,
		asset: this.props.asset,
		omsetPerbulan: this.props.omsetPerbulan,
		tenagaKerjaLaki: this.props.tenagaKerjaLaki,
		tenagaKerjaPerempuan: this.props.tenagaKerjaPerempuan,
		wilayahPemasaranDesa: this.props.wilayahPemasaranDesa,
		wilayahPemasaranKabKota: this.props.wilayahPemasaranKabKota,
		wilayahPemasaranProv: this.props.wilayahPemasaranProv,
		wilayahPemasaranNasional: this.props.wilayahPemasaranNasional,
		wilayahPemasaranExport: this.props.wilayahPemasaranExport,
		kapasitasProdukPerhari: this.props.kapasitasProdukPerhari,
		permasalahan: this.props.permasalahan,
		saranSolusi: this.props.saranSolusi,
		tanggal: this.props.tanggal,
		longitude: this.props.longitude,
		latitude: this.props.latitude,
		petugasPendata: this.props.petugasPendata,
		token_api: REST_KEY,
	};

	toggle = () => {
		this.setState({
			modal2: !this.state.modal2,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.put('umkm/update', this.state).then((response) => {
			let status = response.data;
			this.setState({ message: status.message, title: status.title, type: status.type });
		});
		this.setState({
			modal2: false,
		});
		store.addNotification({
			title: this.state.title,
			message: this.state.message,
			type: this.state.type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};
	render() {
		return (
			<>
				<button className="btn btn-primary btn-small px-2" href="#" onClick={this.toggle}>
					<i className="fa fa-edit" aria-hidden="true"></i>
				</button>
				<MDBModal isOpen={this.state.modal2} toggle={this.toggle} className="mw-100" position="bottom">
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Edit Data UMKM <span className="text-success">{this.props.namaUMKM}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<h6 className="text-center text-primary font-italic">Form Dasar</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBInput
											name="idUMKM"
											value={this.state.idUMKM}
											onChange={this.handlerChange}
											hidden
										/>
										<MDBCol md="6">
											<MDBInput
												label="Nama UMKM"
												name="namaUMKM"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaUMKM}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="namaPemilik"
												label="Nama Pemilik"
												icon="user-tie"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaPemilik}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Alamat UMKM</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="alamat"
												label="Alamat"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.alamat}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="desaKelurahan"
												label="Desa Kelurahan"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.desaKelurahan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="kecamatan"
												label="Kecamatan"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.kecamatan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="kabupatenKota"
												label="Kabupaten/Kota"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.kabupatenKota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Nomor Pokok WajiB Pajak (NPWP), SIUP/IUMK, Tahun Berdiri
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="NIB"
												label="NIB"
												icon="credit-card"
												group
												type="text"
												validate
												value={this.state.NIB}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="SIUP_IUMK"
												label="SIUP/IUMK"
												icon="credit-card"
												group
												type="text"
												validate
												value={this.state.SIUP_IUMK}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="NPWP"
												label="NPWP"
												icon="credit-card"
												group
												type="text"
												validate
												value={this.state.NPWP}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="tahunBerdiri"
												label="Tahun Berdiri"
												icon="calendar-week"
												group
												type="number"
												min={1900}
												max={3000}
												validate
												value={this.state.tahunBerdiri}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Produk Usaha</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa1"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa1}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa2"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa2}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa3"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa3}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa4"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa4}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jenisProdukJasa5"
												label="Jenis Produk/Jasa"
												icon="magic"
												group
												type="text"
												validate
												value={this.state.jenisProdukJasa5}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">No. Sertifikasi</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiPIRT"
												label="P.IRT"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiPIRT}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiBPOM"
												label="BPOM"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiBPOM}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiHalal"
												label="Halal"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiHalal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiHakMerk"
												label="Hak Merk"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiHakMerk}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="sertifikasiHakCipta"
												label="Hak Cipta"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.sertifikasiHakCipta}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Telp/HP & Media Social
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fab icon="whatsapp" />
													</span>
												</div>
												<input
													type="text"
													name="HP1"
													className="form-control"
													placeholder="HP/Whatsapp"
													aria-label="HP/Whatsapp"
													aria-describedby="basic-addon"
													value={this.state.HP1}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fab icon="whatsapp" />
													</span>
												</div>
												<input
													type="text"
													name="HP2"
													className="form-control"
													placeholder="HP/Whatsapp"
													aria-label="HP/Whatsapp"
													aria-describedby="basic-addon"
													value={this.state.HP2}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fas icon="at" />
													</span>
												</div>
												<input
													type="text"
													name="email"
													className="form-control"
													placeholder="Email"
													aria-label="Email"
													aria-describedby="basic-addon"
													value={this.state.email}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fas icon="globe" />
													</span>
												</div>
												<input
													type="text"
													name="website"
													className="form-control"
													placeholder="Website"
													aria-label="Website"
													aria-describedby="basic-addon"
													value={this.state.website}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<i className="fab fa-facebook prefix"></i>
													</span>
												</div>
												<input
													type="text"
													name="facebook"
													className="form-control"
													placeholder="Facebook"
													aria-label="Facebook"
													aria-describedby="basic-addon"
													value={this.state.facebook}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="basic-addon">
														<MDBIcon fab icon="instagram" />
													</span>
												</div>
												<input
													type="text"
													name="instagram"
													className="form-control"
													placeholder="Instagram"
													aria-label="Instagram"
													aria-describedby="basic-addon"
													value={this.state.instagram}
													onChange={this.handlerChange}
												/>
											</div>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Platform E-Commerce Penjualan
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce1"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce1}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce2"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce2}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce3"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce3}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce4"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce4}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="eCommerce5"
												label="E-Commerce"
												icon="shopping-cart"
												group
												type="text"
												validate
												value={this.state.eCommerce5}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Asset, Omset, Jumlah Tenaga Kerja dan Kapasitas Produk Dalam Sehari
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="4">
											<MDBInput
												name="tenagaKerjaLaki"
												label="Tenaga Kerja Laki-Laki"
												icon="mars-double"
												group
												type="number"
												min={0}
												max={300000}
												validate
												value={this.state.tenagaKerjaLaki}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="tenagaKerjaPerempuan"
												label="Tenaga Kerja Perempuan"
												icon="venus-double"
												group
												type="number"
												min={0}
												max={300000}
												validate
												value={this.state.tenagaKerjaPerempuan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="Total Tenaga Kerja"
												icon="equals"
												name=""
												group
												type="number"
												validate
												disabled
												value={
													parseInt(this.state.tenagaKerjaLaki) +
													parseInt(this.state.tenagaKerjaPerempuan)
												}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="asset"
												label="Asset (diluar tanah dan bangunan)"
												icon="home"
												group
												type="number"
												validate
												value={this.state.asset}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="omsetPerbulan"
												label="Omset Perbulan"
												icon="file-invoice-dollar"
												group
												type="number"
												validate
												value={this.state.omsetPerbulan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="kapasitasProdukPerhari"
												label="Kapasitas Produk Perhari"
												icon="warehouse"
												group
												type="number"
												validate
												value={this.state.kapasitasProdukPerhari}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Wilayah Pemasaran</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranDesa"
												label="Desa/Kelurahan: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranDesa}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranKabKota"
												label="Kab/Kota: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranKabKota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranProv"
												label="Provinsi: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranProv}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranNasional"
												label="Nasional: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranNasional}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="wilayahPemasaranExport"
												label="Export: "
												icon="map"
												group
												type="text"
												validate
												value={this.state.wilayahPemasaranExport}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Permasalahan dan Solusi
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="6">
											<MDBInput
												name="permasalahan"
												label="Permasalahan yang dihadapi"
												icon="dizzy"
												group
												type="text"
												validate
												value={this.state.permasalahan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="saranSolusi"
												label="Saran dan Solusi"
												icon="check-square"
												group
												type="text"
												validate
												value={this.state.saranSolusi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Lokasi Google Maps</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="12" className="text-center">
											<MDBBtn
												href="https://www.openstreetmap.org/search?query=kotamobagu#map=14/0.7283/124.3065"
												target="_blank"
												gradient="aqua"
											>
												<MDBIcon icon="map-marked-alt fa-2x" />
											</MDBBtn>
										</MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="latitude"
												label="Latitude"
												icon="map-marked"
												group
												type="number"
												validate
												value={this.state.latitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="longitude"
												label="Longitude"
												icon="map-marked-alt"
												group
												type="text"
												validate
												value={this.state.longitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Petugas Pendata</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="petugasPendata"
												label="Nama:"
												icon="file-signature"
												group
												type="text"
												validate
												value={this.state.petugasPendata}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="tanggal"
												label="Didata pada tanggal:"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.tanggal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default UpdateUmkm;
