import React, { Component } from 'react';
import { MDBRow, MDBContainer } from 'mdbreact';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import SideNavs from '../../../components/AdminPage/SideNav';
import CardUser from './CardUser';
import Api from '../../../services/Api';
import AddUser from './AddUser';
import { REST_KEY } from '../../../config';

export default class AdminPage extends Component {
	constructor(props) {
		super(props);
		let idUser = sessionStorage.idUser;
		let role = sessionStorage.role;
		if (role !== '1') {
			this.state = {
				loading: true,
				users: [],
				token_api: REST_KEY,
				idUser: idUser,
			};
		} else {
			this.state = {
				loading: true,
				users: [],
				token_api: REST_KEY,
			};
		}
	}
	componentDidMount = async () => {
		await Api.post('userapi/list', this.state).then((response) =>
			this.setState({
				users: response.data,
				loading: false,
			})
		);
	};
	render() {
		const usersData = this.state.users.map((data) => {
			return <CardUser data={data} key={data.idUser} refresh={this.componentDidMount} />;
		});
		let role = sessionStorage.role;
		let tambahUser = '';
		if (role === '1') {
			tambahUser = <AddUser refresh={this.componentDidMount} />;
		} else {
			tambahUser = '';
		}
		const loading = this.state.loading;
		return (
			<>
				<ReactNotification />
				<MDBContainer fluid>
					<div className="text-center">
						<h3 className="text-center text-primary">User Manajemen</h3>
						{tambahUser}
						<hr />
					</div>
					<SideNavs />
					{loading && (
						<div className="text-center">
							<p>Loading....</p>
							<div className="spinner-border text-primary" role="status">
								<span className="sr-only">Loading...</span>
							</div>
							<div className="spinner-border text-success" role="status">
								<span className="sr-only">Loading...</span>
							</div>
							<div className="spinner-border text-danger" role="status">
								<span className="sr-only">Loading...</span>
							</div>
							<div className="spinner-border text-warning" role="status">
								<span className="sr-only">Loading...</span>
							</div>
							<div className="spinner-border text-info" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
					<MDBRow>{usersData} </MDBRow>
				</MDBContainer>
			</>
		);
	}
}
