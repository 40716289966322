import React, { Component } from 'react';
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBInput,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBBadge,
} from 'mdbreact';
import Api from '../../../../services/Api';
import { REST_KEY, WS_URL } from '../../../../config';
import { store } from 'react-notifications-component';
import Select from 'react-select';

class TambahPasar extends Component {
	state = {
		modalTambah: false,
		token_api: REST_KEY,
		loading: false,
	};

	toggle = () => {
		this.setState({
			modalTambah: !this.state.modalTambah,
			idKelurahanDesa: '',
			namaPasar: '',
			deskPasar: '',
			longitude: '',
			latitude: '',
			kelurahan: this.props.kelurahan,
			fotoPasar: null,
			fotoPreview: WS_URL + 'assets/upload/pasar/thumbs/pasar.png',
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	fotoChange = (e) => {
		this.setState({
			fotoPreview: URL.createObjectURL(e.target.files[0]),
			fotoPasar: e.target.files[0],
		});
	};

	selectChange = (selectedOption) => {
		this.setState({ [selectedOption.name]: selectedOption.value });
	};

	handlerSubmit = async (e) => {
		e.preventDefault();
		this.setState({
			loading: true,
		});
		const fd = new FormData();
		fd.append('file', this.state.fotoPasar);
		fd.append('token_api', this.state.token_api);
		fd.append('idKelurahanDesa', this.state.idKelurahanDesa);
		fd.append('namaPasar', this.state.namaPasar);
		fd.append('deskPasar', this.state.deskPasar);
		fd.append('longitude', this.state.longitude);
		fd.append('latitude', this.state.latitude);

		await Api.post('master/insertpasar', fd).then((response) => {
			let status = response.data;
			this.setState({
				message: status.message,
				title: status.title,
				type: status.type,
				loading: false,
			});
		});
		this.setState({
			modalTambah: false,
		});
		store.addNotification({
			title: this.state.title,
			message: this.state.message,
			type: this.state.type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};

	render() {
		const loading = this.state.loading;
		return (
			<>
				<button className="btn btn-success btn-small px-2" onClick={this.toggle}>
					<i className="fa fa-plus" aria-hidden="true"></i> Tambah Data
				</button>
				<MDBModal
					isOpen={this.state.modalTambah}
					toggle={this.toggle}
					className="mw-100"
					fullHeight
					position="right"
				>
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Tambah Data <span className="text-success">{this.props.namaKoperasi}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<MDBRow>
										<MDBCol md="12">
											<MDBBadge color="primary">Pilih Kelurahan/Desa</MDBBadge>
											<Select
												options={this.state.kelurahan}
												placeholder="Pilih Kelurahan"
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="Nama Pasar/Toko/Warung"
												name="namaPasar"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaPasar}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="Deskripsi Pasar/Toko/Warung"
												name="deskPasar"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.deskPasar}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="12" className="text-center">
											<MDBBtn
												href="https://www.openstreetmap.org/search?query=kotamobagu#map=14/0.7283/124.3065"
												target="_blank"
												color="primary"
											>
												Referensi
											</MDBBtn>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="Latitude"
												name="latitude"
												icon="landmark"
												group
												type="number"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.latitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="Longitude"
												name="longitude"
												icon="landmark"
												group
												type="number"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.longitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>

										<MDBCol md="12">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="inputFotoPasar">
														Foto Pasar
													</span>
												</div>
												<div className="custom-file">
													<input
														type="file"
														className="custom-file-input"
														id="inputFotoPasar2"
														aria-describedby="inputFotoPasar"
														accept="image/x-png,image/gif,image/jpeg"
														onChange={this.fotoChange}
													/>
													<label className="custom-file-label" htmlFor="inputFotoPasar2">
														Pilih Foto
													</label>
												</div>
											</div>
											{loading && (
												<div className="text-center">
													<p>Olatpa....</p>
													<div className="spinner-border text-primary" role="status">
														<span className="sr-only">Loading...</span>
													</div>
													<div className="spinner-border text-success" role="status">
														<span className="sr-only">Loading...</span>
													</div>
													<div className="spinner-border text-danger" role="status">
														<span className="sr-only">Loading...</span>
													</div>
													<div className="spinner-border text-warning" role="status">
														<span className="sr-only">Loading...</span>
													</div>
													<div className="spinner-border text-info" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												</div>
											)}
											<img
												src={this.state.fotoPreview}
												alt="thumbnail"
												className="img-thumbnail"
												height="100%"
												width="100%"
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default TambahPasar;
