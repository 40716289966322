import React, { Component } from 'react';
import { MDBFooter, MDBAnimation } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

class App extends Component {
	render() {
		return (
			<Router basename={process.env.PUBLIC_URL}>
				<div className="flyout">
					<main style={{ marginTop: '4rem' }}>
						<MDBAnimation>
							<Routes />
						</MDBAnimation>
					</main>
					<MDBFooter color="indigo">
						<p className="footer-copyright mb-0 py-3 text-center">
							&copy; 2020 Copyright:
							<a href="http://kotamobagukota.go.id" target="_blank" rel="noopener noreferrer">
								Pemerintah Kotamobagu
							</a>
						</p>
					</MDBFooter>
				</div>
			</Router>
		);
	}
}

export default App;
