import React, { Component } from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import { WS_URL } from '../../../../config';

class ViewImagePasar extends Component {
	state = {
		modalHapus: false,
	};

	toggle = () => {
		this.setState({
			modalHapus: !this.state.modalHapus,
			fotoPasar: this.props.fotoPasar,
		});
	};

	render() {
		return (
			<>
				<button className="btn btn-primary btn-small px-2" href="#" onClick={this.toggle}>
					<i className="fas fa-image" aria-hidden="true"></i>
				</button>
				<MDBModal isOpen={this.state.modalHapus} toggle={this.toggle} size="lg" position="bottom">
					<MDBModalHeader toggle={this.toggle} className="text-center">
						{this.props.namaPasar}
					</MDBModalHeader>
					<MDBModalBody>
						<img
							src={WS_URL + 'assets/upload/pasar/thumbs/' + this.state.fotoPasar}
							alt="thumbnail"
							className="img-thumbnail"
							height="100%"
							width="100%"
						/>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default ViewImagePasar;
