import React from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import SideNavs from '../../../components/AdminPage/SideNav';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';
import TambahUmkm from './TambahUmkm';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import UpdateUmkm from './UpdateUmkm';
import DelUmkm from './DelUmkm';
import PrintUMKM from './PrintUMKM';

class Umkm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
			token_api: REST_KEY,
		};
	}

	componentDidMount = async () => {
		await Api.post('umkm/list', this.state).then((response) => {
			let data = response.data;
			let { rows } = response.data;
			let columns = [
				{
					label: 'Action',
					field: 'action',
					width: 120,
				},
				{
					label: 'Nama Usaha',
					field: 'namaUMKM',
					sort: 'asc',
					width: 100,
				},
				{
					label: 'Nama Pemilik',
					field: 'namaPemilik',
					sort: 'asc',
					width: 100,
				},
				{
					label: 'Alamat',
					field: 'alamat',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Nomor NIB',
					field: 'NIB',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'No SIUP/IUMK',
					field: 'SIUP_IUMK',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Tahun Berdiri',
					field: 'tahunBerdiri',
					sort: 'asc',
					width: 150,
				},
				{
					label: 'Salah Satu Produk',
					field: 'jenisProdukJasa1',
					sort: 'asc',
					width: 150,
				},
			];

			rows = rows.map((row) => ({
				...row,
				action: (
					<MDBRow className="ml-1">
						<UpdateUmkm
							idUMKM={row.idUMKM}
							namaUMKM={row.namaUMKM}
							namaPemilik={row.namaPemilik}
							alamat={row.alamat}
							desaKelurahan={row.desaKelurahan}
							kecamatan={row.kecamatan}
							kabupatenKota={row.kabupatenKota}
							NIB={row.NIB}
							SIUP_IUMK={row.SIUP_IUMK}
							NPWP={row.NPWP}
							tahunBerdiri={row.tahunBerdiri}
							jenisProdukJasa1={row.jenisProdukJasa1}
							jenisProdukJasa2={row.jenisProdukJasa2}
							jenisProdukJasa3={row.jenisProdukJasa3}
							jenisProdukJasa4={row.jenisProdukJasa4}
							jenisProdukJasa5={row.jenisProdukJasa5}
							sertifikasiPIRT={row.sertifikasiPIRT}
							sertifikasiBPOM={row.sertifikasiBPOM}
							sertifikasiHalal={row.sertifikasiHalal}
							sertifikasiHakMerk={row.sertifikasiHakMerk}
							sertifikasiHakCipta={row.sertifikasiHakCipta}
							HP1={row.HP1}
							HP2={row.HP2}
							email={row.email}
							website={row.website}
							facebook={row.facebook}
							instagram={row.instagram}
							eCommerce1={row.eCommerce1}
							eCommerce2={row.eCommerce2}
							eCommerce3={row.eCommerce3}
							eCommerce4={row.eCommerce4}
							eCommerce5={row.eCommerce5}
							asset={row.asset}
							omsetPerbulan={row.omsetPerbulan}
							tenagaKerjaLaki={row.tenagaKerjaLaki}
							tenagaKerjaPerempuan={row.tenagaKerjaPerempuan}
							wilayahPemasaranDesa={row.wilayahPemasaranDesa}
							wilayahPemasaranKabKota={row.wilayahPemasaranKabKota}
							wilayahPemasaranProv={row.wilayahPemasaranProv}
							wilayahPemasaranNasional={row.wilayahPemasaranNasional}
							wilayahPemasaranExport={row.wilayahPemasaranExport}
							kapasitasProdukPerhari={row.kapasitasProdukPerhari}
							permasalahan={row.permasalahan}
							saranSolusi={row.saranSolusi}
							tanggal={row.tanggal}
							longitude={row.longitude}
							latitude={row.latitude}
							petugasPendata={row.petugasPendata}
							refresh={this.componentDidMount}
						/>
						<DelUmkm idUMKM={row.idUMKM} namaUMKM={row.namaUMKM} refresh={this.componentDidMount} />
						<PrintUMKM idUMKM={row.idUMKM} namaUMKM={row.namaUMKM} refresh={this.componentDidMount} />
					</MDBRow>
				),
			}));
			data = {
				columns,
				rows,
			};
			this.setState({ data, loading: false });
		});
	};

	render() {
		const { data } = this.state;
		const loading = this.state.loading;
		return (
			<MDBContainer className="mt-3" fluid>
				<ReactNotification />
				<TambahUmkm refresh={this.componentDidMount} />
				<SideNavs />
				<MDBRow className="py-3">
					<MDBCol md="12">
						<MDBCard>
							<MDBCardBody>
								<h3 className="text-center">Daftar Usaha Mikro Kecil Menengah</h3>
								<MDBDataTable striped bordered hover scrollX data={data} sortRows={['id']} />
								{loading && (
									<div className="text-center">
										<p>Loading....</p>
										<div className="spinner-border text-primary" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-success" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-warning" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-info" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default Umkm;

//import NumberFormat from 'react-number-format';
/* <NumberFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */
