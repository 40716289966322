import React from 'react';
import EditUser from './EditUser';
import DelUser from './DelUser';

function CardUser({ data, refresh }) {
	let role = '';
	if (data.role === '1') {
		role = 'Super Admin';
	}
	if (data.role === '2') {
		role = 'Admin Perdagangan';
	}
	if (data.role === '3') {
		role = 'Admin Koperasi';
	}
	if (data.role === '4') {
		role = 'Admin UMKM';
	}
	return (
		<div className="col-sm-2 col-6 mt-2">
			<div className="card testimonial-card text-center">
				<div className="card-up blue lighten-2 mb-2"></div>
				<div className="avatar mx-auto white">
					<img
						className="rounded img-fluid "
						style={{ width: '60%', height: '60%' }}
						src="https://icons-for-free.com/iconfiles/png/512/human+male+profile+user+icon-1320196240448793481.png"
						alt="avatar mx-auto white"
					/>
				</div>

				<div className="card-body card-body-cascade">
					<h5 className="pink-text text-center">
						<small>{role}</small>
					</h5>

					<h6 className="font-weight-bold card-title text-center">{data.namaUser}</h6>
					<div className="text-center">
						<EditUser
							idUser={data.idUser}
							namaUser={data.namaUser}
							usernameUser={data.usernameUser}
							emailUser={data.emailUser}
							password=""
							role={data.role}
							refresh={refresh}
						/>
						<DelUser idUser={data.idUser} namaUser={data.namaUser} refresh={refresh} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CardUser;
