import React, { Component } from 'react';
import { MDBRow } from 'mdbreact';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import SideNavs from '../../../components/AdminPage/SideNav';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';
import LineChart from './LineChart';
import BarChart from './BarChart';
import './css.css';

export default class AdminPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			loggedIn: false,
			token_api: REST_KEY,
			data: '',
		};
	}
	componentDidMount = async () => {
		await Api.post('stats/countall', this.state).then((response) => {
			let data = response.data;
			this.setState({ data: data.rows[0], loading: false });
		});
	};
	render() {
		const loading = this.state.loading;
		const data = this.state.data;
		return (
			<div
				style={{
					overflow: 'hidden',
				}}
			>
				<SideNavs />
				<ReactNotification />
				<MDBRow>
					<div className="col-xl-1"></div>
					<div className="col-xl-5 col-md-6 mb-4">
						<div className="card btn border-left-success shadow h-100 py-2">
							<div className="card-body text-success">
								<div className="row no-gutters align-items-center">
									{/* <div className="col mr-2">
										<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
											Kecamatan
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">{data.kecamatan}</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-success" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div>
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
											Kelurahan/Desa
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">
											{data.kelurahanDesa}
										</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-success" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div> */}
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
											Pasar/toko/warung
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">{data.pasar}</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-success" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div>
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
											Koperasi
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">{data.koperasi}</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-success" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div>
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-success text-uppercase mb-1">
											UMKM
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">{data.umkm}</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-success" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div>
									<div className="col-auto">
										<i className="fas fa-map fa-2x text-grey-300"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-5 col-md-6 mb-4">
						<div className="card btn border-left-danger shadow h-100 py-2">
							<div className="card-body text-danger">
								<div className="row no-gutters align-items-center">
									{/* <div className="col mr-2">
										<div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
											Kategori Brg.
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">
											{data.kategoriBarang}
										</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-danger" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div>
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
											Satuan
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">{data.satuan}</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-danger" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div> */}
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
											Sub Komoditi
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">{data.subKomoditi}</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-danger" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div>
									<div className="col mr-2">
										<div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
											Komoditi
										</div>
										<div className="h5 mb-0 font-weight-bold text-gray-800">{data.komoditi}</div>
										{loading && (
											<div className="text-center">
												<div className="spinner-border text-danger" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											</div>
										)}
									</div>
									<div className="col-auto">
										<i className="fas fa-carrot fa-2x text-grey-300"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</MDBRow>
				<MDBRow>
					<div className="col-xl-6 col-md-6 mb-4">
						<LineChart />
					</div>
					<div className="col-xl-6 col-md-6 mb-4">
						<BarChart />
					</div>
				</MDBRow>
			</div>
		);
	}
}
