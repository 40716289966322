import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBCol, MDBContainer, MDBRow, MDBInput } from 'mdbreact';
import Select from 'react-select';
import Api from '../../../services/Api';
import { REST_KEY, WS_URL } from '../../../config';

class PrintPerdagangan extends Component {
	state = {
		modalPrint: false,
		token_api: REST_KEY,
		print_url: WS_URL + 'perdagangan/print/',
		start_year: new Date().getFullYear(),
	};

	toggle = async () => {
		const tahun = this.state.start_year;
		const max = tahun + 3;
		const min = tahun - 3;
		const options = [];
		for (var i = min; i < max; i++) {
			var obj = {};
			obj['value'] = i;
			obj['label'] = i;
			obj['name'] = 'tahunPrint';
			options.push(obj);
		}
		this.setState({
			modalPrint: !this.state.modalPrint,
			tahun: options,
			loading: true,
			bulanPrint: null,
			tahunPrint: this.state.start_year,
		});
		await Api.post('perdagangan/oprint', this.state).then((response) => {
			this.setState({
				kategori: response.data.kategori,
				bulan: response.data.bulan,
				idKategoriPrint: response.data.oke,
				loading: false,
			});
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	selectChange = async (selectedOption) => {
		this.setState({ [selectedOption.name]: selectedOption.value });
		console.log(this.state);
	};

	render() {
		const loading = this.state.loading;
		return (
			<>
				<button className="btn purple-gradient btn-small px-2" onClick={this.toggle}>
					<i className="fa fa-print 2x" aria-hidden="true"></i> Cetak
				</button>
				{loading && (
					<div className="text-center">
						<div className="spinner-border text-success" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}
				<MDBModal isOpen={this.state.modalPrint} toggle={this.toggle} position="bottom">
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Cetak Data!!
					</MDBModalHeader>
					<MDBModalBody>
						<form action={this.state.print_url} method="post" target="_blank">
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<MDBRow>
										<MDBCol md="12" className="mb-3">
											<MDBInput
												name="idKategoriPrint"
												value={this.state.idKategoriPrint}
												hidden
											></MDBInput>
											<Select
												options={this.state.kategori}
												placeholder="Pilih Kategori Barang"
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="12" className="mb-3">
											<Select
												name="bulanPrint"
												options={this.state.bulan}
												placeholder="Pilih Bulan"
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="12" className="mb-3">
											<Select
												name="tahunPrint"
												options={this.state.tahun}
												placeholder="Pilih Tahun"
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn gradient="purple" type="submit" name="token_api" value={this.state.token_api}>
									Cetak
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default PrintPerdagangan;
