import React, { Component } from 'react';
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBInput,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBBadge,
} from 'mdbreact';
import Api from '../../../../services/Api';
import { REST_KEY, WS_URL } from '../../../../config';
import { store } from 'react-notifications-component';
import Select from 'react-select';

class EditKomoditi extends Component {
	state = {
		modalTambah: false,
		token_api: REST_KEY,
	};

	toggle = () => {
		this.setState({
			modalTambah: !this.state.modalTambah,
			idKomoditi: this.props.idKomoditi,
			idSubKomoditi: this.props.idSubKomoditi,
			idSatuan: this.props.idSatuan,
			namaKomoditi: this.props.namaKomoditi,
			deskKomoditi: this.props.deskKomoditi,
			subKomoditi: this.props.subKomoditi,
			satuan: this.props.satuan,
			fotoKomoditi: null,
			fotoPreview: WS_URL + 'assets/upload/komoditi/thumbs/' + this.props.fotoKomoditi,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	fotoChange = (e) => {
		this.setState({
			fotoPreview: URL.createObjectURL(e.target.files[0]),
			fotoKomoditi: e.target.files[0],
		});
	};

	selectChange = (selectedOption) => {
		this.setState({ [selectedOption.name]: selectedOption.value });
	};

	handlerSubmit = async (e) => {
		e.preventDefault();
		const fd = new FormData();
		fd.append('file', this.state.fotoKomoditi);
		fd.append('token_api', this.state.token_api);
		fd.append('idKomoditi', this.state.idKomoditi);
		fd.append('idSubKomoditi', this.state.idSubKomoditi);
		fd.append('idSatuan', this.state.idSatuan);
		fd.append('namaKomoditi', this.state.namaKomoditi);
		fd.append('deskKomoditi', this.state.deskKomoditi);

		await Api.post('master/updatekomoditi', fd).then((response) => {
			let status = response.data;
			this.setState({ message: status.message, title: status.title, type: status.type });
		});
		this.setState({
			modalTambah: false,
		});
		store.addNotification({
			title: this.state.title,
			message: this.state.message,
			type: this.state.type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};

	render() {
		return (
			<>
				<button className="btn btn-success btn-small px-2" href="#" onClick={this.toggle}>
					<i className="fa fa-edit" aria-hidden="true"></i>
				</button>
				<MDBModal
					isOpen={this.state.modalTambah}
					toggle={this.toggle}
					className="mw-100"
					fullHeight
					position="right"
				>
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Tambah Data <span className="text-success">{this.state.namaKomoditi}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<MDBRow>
										<MDBCol md="12">
											<MDBBadge color="primary">Pilih Sub Komoditi</MDBBadge>
											<Select
												options={this.state.subKomoditi}
												placeholder={this.props.namaSubKomoditi}
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBBadge color="primary">Pilih Satuan</MDBBadge>
											<Select
												options={this.state.satuan}
												placeholder={this.props.namaSatuan}
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="Nama Komoditi"
												name="namaKomoditi"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaKomoditi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<MDBInput
												label="Deskripsi Komoditi"
												name="deskKomoditi"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.deskKomoditi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="12">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text" id="inputFotoPasar">
														Foto Komoditi
													</span>
												</div>
												<div className="custom-file">
													<input
														type="file"
														className="custom-file-input"
														id="inputFotoPasar2"
														aria-describedby="inputFotoPasar"
														accept="image/x-png,image/gif,image/jpeg"
														onChange={this.fotoChange}
													/>
													<label className="custom-file-label" htmlFor="inputFotoPasar2">
														Pilih Foto
													</label>
												</div>
											</div>
											<img
												src={this.state.fotoPreview}
												alt="thumbnail"
												className="img-thumbnail"
												height="100%"
												width="100%"
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default EditKomoditi;
