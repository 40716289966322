import React from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import SideNavs from '../../../components/NavBar/Navbar';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import PrintPerdagangan from './PrintPerdagangan';

class Perdagangan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: true,
			token_api: REST_KEY,
		};
	}

		componentDidMount = async () => {
			await Api.post('perdagangan/list', this.state).then((response) => {
				this.setState({
					komoditi: response.data.komoditi,
					pasar: response.data.pasar,
				});
				let data = response.data;
				let { rows } = response.data;
				let columns = [
					{
						label: 'Sub Komoditi',
						field: 'namaSubKomoditi',
						sort: 'asc',
						width: 100,
					},
					{
						label: 'Komoditi',
						field: 'namaKomoditi2',
						sort: 'asc',
						width: 100,
					},
					{
						label: 'Satuan',
						field: 'namaSatuan2',
						sort: 'asc',
						width: 100,
					},
					{
						label: 'Pasar',
						field: 'namaPasar',
						sort: 'asc',
						width: 100,
					},
					{
						label: 'Harga',
						field: 'harga2',
						sort: 'asc',
						width: 100,
					},
					{
						label: 'Minggu',
						field: 'minggu2',
						sort: 'asc',
						width: 100,
					},
					{
						label: 'Tanggal',
						field: 'tanggal2',
						sort: 'asc',
						width: 100,
					},
				];

				rows = rows.map((row) => ({
					...row,
					namaSatuan2: (
						<div>
							{row.namaSatuan}({row.singkatanSatuan})
						</div>
					),
					namaKomoditi2: <div>{row.namaKomoditi}</div>,
					harga2: <div>Rp. {row.harga2}</div>,
					tanggal2: <div>{row.tanggal2}</div>,
					minggu2: <div>Ke- {row.minggu}</div>,
				}));

				data = {
					columns,
					rows,
				};
				this.setState({ data, loading: false });
			});
		};

	render() {
		const { data } = this.state;
		const loading = this.state.loading;
		return (
			<MDBContainer className="mt-3" fluid>
				<ReactNotification />
				<MDBRow>
					<PrintPerdagangan refresh={this.componentDidMount} />
				</MDBRow>
				<SideNavs />
				<MDBRow className="py-3">
					<MDBCol md="12">
						<MDBCard>
							<MDBCardBody>
								<h3 className="text-center">Daftar Harga Barang</h3>
								<MDBDataTable striped bordered hover scrollX data={data} sortRows={['id']} />
								{loading && (
									<div className="text-center">
										<p>Loading....</p>
										<div className="spinner-border text-primary" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-success" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-warning" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<div className="spinner-border text-info" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
export default Perdagangan;

//import NumberFormat from 'react-number-format';
/* <NumberFormat value={2456981} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */
