import React, { Component } from 'react';
import { MDBModal, MDBModalBody, MDBInput, MDBModalHeader, MDBIcon } from 'mdbreact';
import { Redirect } from 'react-router-dom';
import { store } from 'react-notifications-component';
	import Api from '../../services/Api';
import { REST_KEY } from '../../config';

export default class ModalLogin extends Component {
	constructor(props) {
		super(props);
		const token = localStorage.getItem('token');
		if (token != null) {
			let loggedIn = true;

			this.state = {
				loggedIn,
				loading: false,
			};
		} else {
			this.state = {
				modal1: false,
				emailUser: '',
				password: '',
				loggedIn: false,
				token_api: REST_KEY,
				loading: false,
			};
		}

		this.onChange = this.onChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}

	toggle = (nr) => () => {
		const modalNumber = `modal${nr}`;
		this.setState({
			...this.state,
			[modalNumber]: !this.state[modalNumber],
		});
	};

	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	submitForm = async (e) => {
		e.preventDefault();
		this.setState({
			loading: true,
		});
		if (this.state.emailUser && this.state.password) {
			await Api.post('userapi/login', this.state).then((response) =>
				this.setState({
					status: response.data.status,
					data: response.data,
					loading: false,
				})
			);
			if (this.state.status === true) {
				sessionStorage.setItem('role', this.state.data.data.role);
				sessionStorage.setItem('namaUser', this.state.data.data.namaUser);
				sessionStorage.setItem('idUser', this.state.data.data.idUser);
				this.setState({
					loggedIn: true,
				});
				store.addNotification({
					title: 'Niondon Dega!',
					message:
						'Selamat datang ' +
						this.state.data.data.namaUser +
						' di sistem informasi Dinas Perdagangan, Pertanian dan UMKM',
					type: 'success',
					insert: 'top',
					container: 'top-center',
					animationIn: ['animated', 'fadeIn'],
					animationOut: ['animated', 'fadeOut'],
					dismiss: {
						duration: 10000,
						onScreen: true,
					},
				});
			} else {
				store.addNotification({
					title: 'Ada yang salah!',
					message: this.state.data.message,
					type: 'danger',
					insert: 'top',
					container: 'top-center',
					animationIn: ['animated', 'fadeIn'],
					animationOut: ['animated', 'fadeOut'],
					dismiss: {
						duration: 10000,
						onScreen: true,
					},
				});
			}
		}
	};
	render() {
		if (this.state.loggedIn === true) {
			return <Redirect to="/admin/dashboard" />;
		}
		if (sessionStorage.getItem('data')) {
			return <Redirect to="/admin/dashboard" />;
		}
		const loading = this.state.loading;

		const { modal1 } = this.state;
		return (
			<>
				<div>
					<button
						className="border nav-link border-light rounded mr-1 mx-2 mb-2"
						onClick={this.toggle(1)}
						icon="user"
					>
						<MDBIcon icon="user" />
						<span className="font-weight-bold"> Login</span>
					</button>
					<MDBModal isOpen={modal1} toggle={this.toggle(1)}>
						<MDBModalHeader
							className="text-center"
							titleClass="w-100 font-weight-bold"
							toggle={this.toggle(1)}
						>
							Sign in
						</MDBModalHeader>
						<MDBModalBody>
							<form onSubmit={this.submitForm} className="mx-3 grey-text needs-validation">
								<MDBInput
									label="Type your email"
									icon="envelope"
									group
									type="email"
									required
									validate
									error="wrong"
									success="right"
									name="emailUser"
									value={this.state.emailUser}
									onChange={this.onChange}
								/>
								<MDBInput
									label="Type your password"
									icon="lock"
									group
									type="password"
									validate
									required
									name="password"
									value={this.state.password}
									onChange={this.onChange}
								/>
								<input className="btn btn-outline-primary waves-effect" type="submit" value="Login" />
							</form>
							{loading && (
								<div className="text-center">
									<p>Olatpa....</p>
									<div className="spinner-border text-primary" role="status">
										<span className="sr-only">Loading...</span>
									</div>
									<div className="spinner-border text-success" role="status">
										<span className="sr-only">Loading...</span>
									</div>
									<div className="spinner-border text-danger" role="status">
										<span className="sr-only">Loading...</span>
									</div>
									<div className="spinner-border text-warning" role="status">
										<span className="sr-only">Loading...</span>
									</div>
									<div className="spinner-border text-info" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							)}
						</MDBModalBody>
					</MDBModal>
				</div>
			</>
		);
	}
}
