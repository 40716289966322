import React, { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBInput, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import Select from 'react-select';
import Api from '../../../services/Api';
import { REST_KEY } from '../../../config';
import { store } from 'react-notifications-component';

class TambahPerdagangan extends Component {
	state = {
		modalTambah: false,
		token_api: REST_KEY,
		idPasar: '',
		idKomoditi: '',
		komoditi: this.props.komoditi,
		pasar: this.props.pasar,
		minggu: '',
		harga: '',
		tanggal: '',
	};

	toggle = () => {
		this.setState({
			modalTambah: !this.state.modalTambah,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	selectChange = (selectedOption) => {
		this.setState({ [selectedOption.name]: selectedOption.value });
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('perdagangan/insert', this.state);
		this.setState({
			modalTambah: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data berhasil di Tambahkan',
			type: 'success',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};

	render() {
		return (
			<>
				<button className="btn btn-success btn-small px-2" onClick={this.toggle}>
					<i className="fa fa-plus" aria-hidden="true"></i> Tambah Data
				</button>
				<MDBModal
					isOpen={this.state.modalTambah}
					toggle={this.toggle}
					className="mw-100"
					fullHeight
					position="bottom"
				>
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Tambah Data <span className="text-success">{this.props.namaKoperasi}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<Select
												name="idKomoditi"
												options={this.props.komoditi}
												placeholder="Pilih Komoditi"
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<Select
												name="idPasar"
												options={this.props.pasar}
												placeholder="Pilih Pasar"
												menuColor="red"
												onChange={this.selectChange}
											/>
										</MDBCol>
										<MDBCol md="2"></MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="4">
											<MDBInput
												label="Harga"
												name="harga"
												icon="landmark"
												group
												type="number"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.harga}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="Minggu Ke-"
												name="minggu"
												icon="landmark"
												group
												type="number"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.minggu}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="tanggal"
												name="tanggal"
												icon="landmark"
												group
												type="date"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.tanggal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default TambahPerdagangan;
