import React, { Component } from 'react';
import './LinkBar.css';
import { MDBRow, MDBCol, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBLink } from 'mdbreact';
import Kecamatan from './Kecamatan/Kecamatan';
import Kelurahan from './Kelurahan/Kelurahan';
import Pasar from './Pasar/Pasar';
import KategoriBarang from './KategoriBarang/KategoriBarang';
import Satuan from './Satuan/Satuan';
import SubKomoditi from './SubKomoditi/SubKomoditi';
import Komoditi from './Komoditi/Komoditi';
import 'react-notifications-component/dist/theme.css';

class PillsPage extends Component {
	state = {
		activeItemPills: '1',
		muncul: false,
	};

	togglePills = (tab) => () => {
		const { activePills } = this.state;
		if (activePills !== tab) {
			this.setState({
				activeItemPills: tab,
			});
		}
	};
	toggleMuncul = () => {
		this.setState({
			muncul: !this.state.muncul,
		});
	};

	render() {
		const { activeItemPills } = this.state;

		return (
			<>
				<MDBRow className="mt-2">
					<MDBCol md="12">
						<div className="menu-berger" onClick={this.toggleMuncul}>
							<input type="checkbox" />
							<span></span>
							<span></span>
							<span></span>
						</div>
						<MDBNav className={'nav-pillss text-center ' + this.state.muncul}>
							<MDBNavItem>
								<MDBLink to="#" active={activeItemPills === '1'} onClick={this.togglePills('1')} link>
									Kecamatan
								</MDBLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBLink to="#" active={activeItemPills === '2'} onClick={this.togglePills('2')} link>
									Kelurahan/Desa
								</MDBLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBLink to="#" active={activeItemPills === '3'} onClick={this.togglePills('3')} link>
									Pasar
								</MDBLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBLink to="#" active={activeItemPills === '4'} onClick={this.togglePills('4')} link>
									Kategori Barang
								</MDBLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBLink to="#" active={activeItemPills === '5'} onClick={this.togglePills('5')} link>
									Satuan
								</MDBLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBLink to="#" active={activeItemPills === '6'} onClick={this.togglePills('6')} link>
									Sub Komoditi
								</MDBLink>
							</MDBNavItem>
							<MDBNavItem>
								<MDBLink to="#" active={activeItemPills === '7'} onClick={this.togglePills('7')} link>
									Komoditi
								</MDBLink>
							</MDBNavItem>
						</MDBNav>
						<hr />
						<MDBTabContent activeItem={activeItemPills}>
							<MDBTabPane tabId="1">
								<Kecamatan />
							</MDBTabPane>
							<MDBTabPane tabId="2">
								<Kelurahan />
							</MDBTabPane>
							<MDBTabPane tabId="3">
								<Pasar />
							</MDBTabPane>
							<MDBTabPane tabId="4">
								<KategoriBarang />
							</MDBTabPane>
							<MDBTabPane tabId="5">
								<Satuan />
							</MDBTabPane>
							<MDBTabPane tabId="6">
								<SubKomoditi />
							</MDBTabPane>
							<MDBTabPane tabId="7">
								<Komoditi />
							</MDBTabPane>
						</MDBTabContent>
					</MDBCol>
				</MDBRow>
			</>
		);
	}
}

export default PillsPage;
