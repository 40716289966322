import React, { Component } from 'react';
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBInput,
	MDBCol,
	MDBContainer,
	MDBRow,
	MDBBadge,
	MDBIcon,
} from 'mdbreact';
import Api from '../../../services/Api';
import { store } from 'react-notifications-component';

class TambahKoperasi extends Component {
	state = {
		modal: false,
		jumlahKoperasi: '',
		statusKoperasi: '',
		NIKKoperasi: '',
		noSertifikatKoperasi: '',
		namaKoperasi: '',
		noAktaPendirian: '',
		tanggalAktaPendirian: '',
		noPAD: '',
		tanggalPAD: '',
		noPengesahanAktaPendirian: '',
		tanggalPengesahanAktaPendirian: '',
		noPengesahanAktaPAD: '',
		tanggalPengesahanAktaPAD: '',
		alamat: '',
		desaKelurahan: '',
		kecamatan: '',
		kabupatenKota: '',
		npwp: '',
		masaBaktiTahun: '',
		masaBaktiAwal: '',
		masaBaktiAkhir: '',
		bentukKoperasi: '',
		jenisKoperasi: '',
		kegiatanJenisUsaha: '',
		kegiatanUsahaAktif: '',
		namaPengurusKetua: '',
		hpPengurusKetua: '',
		namaPengurusSekretaris: '',
		hpPengurusSekretaris: '',
		namaPengurusBendahara: '',
		hpPengurusBendahara: '',
		namaPengawasKetua: '',
		hpPengawasKetua: '',
		namaPengawasSekretaris: '',
		hpPengawasSekretaris: '',
		namaPengawasAnggota: '',
		hpPengawasAnggota: '',
		namaManager: '',
		jumlahKaryawanLaki: 0,
		jumlahKaryawanPerempuan: 0,
		jumlahAnggotaPenuhLaki: 0,
		jumlahAnggotaPenuhPerempuan: 0,
		JumlahcalonAnggota: 0,
		pelaksanaanRAT1Tahun: '',
		pelaksanaanRAT1Tanggal: '',
		pelaksanaanRAT2Tahun: '',
		pelaksanaanRAT2Tanggal: '',
		pelaksanaanRAT3Tahun: '',
		pelaksanaanRAT3Tanggal: '',
		indikatorUsahaModalSendiri: '',
		indikatorUsahaModalLuar: '',
		indikatorUsahaAsset: '',
		indikatorSHU: '',
		longitude: '',
		latitude: '',
		petugasPendata: '',
		token_api: 'defaultrestkey',
	};

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	handlerChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handlerSubmit = async (e) => {
		e.preventDefault();
		await Api.post('koperasi/insert', this.state);
		this.setState({
			modal: false,
		});
		store.addNotification({
			title: 'Sukses!',
			message: 'Data ' + this.state.namaKoperasi + ' berhasil di Tambahkan',
			type: 'success',
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: {
				duration: 10000,
				onScreen: true,
			},
		});
		return this.props.refresh();
	};
	render() {
		return (
			<>
				<button className="btn btn-success btn-small px-2" onClick={this.toggle}>
					<i className="fa fa-plus" aria-hidden="true"></i> Tambah Koperasi
				</button>

				<MDBModal isOpen={this.state.modal} toggle={this.toggle} className="mw-100" position="bottom">
					<MDBModalHeader toggle={this.toggle} className="text-center">
						Tambah Data <span className="text-success">{this.props.namaKoperasi}</span>
					</MDBModalHeader>
					<MDBModalBody>
						<form onSubmit={this.handlerSubmit}>
							<div className="grey-text text-left">
								<MDBContainer fluid>
									<h6 className="text-center text-primary font-italic">Form Dasar</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="idKoperasi"
												value={this.state.idKoperasi}
												onChange={this.handlerChange}
												hidden
											/>
											<MDBInput
												label="Nama Koperasi"
												name="namaKoperasi"
												icon="landmark"
												group
												type="text"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.namaKoperasi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="jumlahKoperasi"
												label="Jumlah Koperasi"
												icon="check-double"
												group
												type="number"
												required
												validate
												error="wrong"
												success="right"
												value={this.state.jumlahKoperasi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<div className="mt-2">
												<MDBBadge pill color="light">
													Status
												</MDBBadge>
												<select
													className="browser-default custom-select"
													name="statusKoperasi"
													value={this.state.statusKoperasi}
													onChange={this.handlerChange}
												>
													<option value="Aktif">Aktif</option>
													<option value="Tidak Aktif">Tidak Aktif</option>
												</select>
											</div>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="NIKKoperasi"
												label="NIK Koperasi"
												icon="id-card"
												group
												type="number"
												validate
												value={this.state.NIKKoperasi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="noSertifikatKoperasi"
												label="No Sertifikat"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.noSertifikatKoperasi}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Akta Pendirian & Akta Perubahan Anggaran Dasar (PAD)
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="noAktaPendirian"
												label="No Akta Pendirian"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.noAktaPendirian}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="tanggalAktaPendirian"
												label="Tanggal Akta Pendirian"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.tanggalAktaPendirian}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="noPAD"
												label="No PAD"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.noPAD}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="tanggalPAD"
												label="Tanggal PAD"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.tanggalPAD}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="noPengesahanAktaPendirian"
												label="NO Pengesahan Akta Pendirian"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.noPengesahanAktaPendirian}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="tanggalPengesahanAktaPendirian"
												label="Tanggal Pengesahan Akta Pendirian"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.tanggalPengesahanAktaPendirian}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="noPengesahanAktaPAD"
												label="NO Pengesahan Akta PAD"
												icon="certificate"
												group
												type="text"
												validate
												value={this.state.noPengesahanAktaPAD}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="tanggalPengesahanAktaPAD"
												label="Tanggal Pengesahan Akta PAD"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.tanggalPengesahanAktaPAD}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Alamat Koperasi</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="alamat"
												label="Alamat"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.alamat}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="desaKelurahan"
												label="Desa Kelurahan"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.desaKelurahan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="kecamatan"
												label="Kecamatan"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.kecamatan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="kabupatenKota"
												label="Kabupaten/Kota"
												icon="location-arrow"
												group
												type="text"
												validate
												value={this.state.kabupatenKota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Nomor Pokok WajiB Pajak (NPWP)
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="npwp"
												label="NPWP"
												icon="credit-card"
												group
												type="number"
												validate
												value={this.state.npwp}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Kegiatan/Usaha Koperasi
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="4"></MDBCol>
										<MDBCol md="2">
											<div className="mt-2">
												<MDBBadge pill color="light">
													Bentuk Koperasi
												</MDBBadge>
												<select
													className="browser-default custom-select"
													name="bentukKoperasi"
													value={this.state.bentukKoperasi}
													onChange={this.handlerChange}
												>
													<option value="Primer Kab/Kota">Primer Kab/Kota</option>
													<option value="Primer Provinsi">Primer Provinsi</option>
													<option value="Primer Nasional">Primer Nasional</option>
													<option value="Sekunder Kab/Kota">Sekunder Kab/Kota</option>
													<option value="Sekunder Provinsi">Sekunder Provinsi</option>
													<option value="Sekunder Nasional">Sekunder Nasional</option>
												</select>
											</div>
										</MDBCol>
										<MDBCol md="2">
											<div className="mt-2">
												<MDBBadge pill color="light">
													Jenis Koperasi
												</MDBBadge>
												<select
													className="browser-default custom-select"
													name="jenisKoperasi"
													value={this.state.jenisKoperasi}
													onChange={this.handlerChange}
												>
													<option value="Produsen">Produsen</option>
													<option value="Pemasaran">Pemasaran</option>
													<option value="Simpan Pinjam">Simpan Pinjam</option>
													<option value="Konsumen">Konsumen</option>
													<option value="Jasa">Jasa</option>
												</select>
											</div>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="2"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="kegiatanJenisUsaha"
												label="Kegiatan Jenis Usaha Berdasarkan Akta Pendirian/PAD"
												icon="tractor"
												group
												type="text"
												validate
												value={this.state.kegiatanJenisUsaha}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="kegiatanUsahaAktif"
												label="Kegiatan Usaha Aktif"
												icon="tractor"
												group
												type="text"
												validate
												value={this.state.kegiatanUsahaAktif}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Pengurus / Pengawas Koperasi
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="namaPengurusKetua"
												label="Ketua Pengurus"
												icon="users"
												group
												type="text"
												validate
												value={this.state.namaPengurusKetua}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="hpPengurusKetua"
												label="No HP Ketua Pengurus"
												icon="mobile-alt"
												group
												type="text"
												validate
												value={this.state.hpPengurusKetua}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="namaPengurusSekretaris"
												label="Sekretaris Pengurus"
												icon="users"
												group
												type="text"
												validate
												value={this.state.namaPengurusSekretaris}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="hpPengurusSekretaris"
												label="Hp HP Sekretaris Pengurus"
												icon="mobile-alt"
												group
												type="text"
												validate
												value={this.state.hpPengurusSekretaris}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="namaPengurusBendahara"
												label="Bendahara Pengurus"
												icon="users"
												group
												type="text"
												validate
												value={this.state.namaPengurusBendahara}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="hpPengurusBendahara"
												label="No HP Bendahara Pengurus"
												icon="mobile-alt"
												group
												type="text"
												validate
												value={this.state.hpPengurusBendahara}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="namaPengawasKetua"
												label="Ketua Pengawas"
												icon="user-shield"
												group
												type="text"
												validate
												value={this.state.namaPengawasKetua}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="hpPengawasKetua"
												label="No HP Ketua Pengawas"
												icon="mobile-alt"
												group
												type="text"
												validate
												value={this.state.hpPengawasKetua}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="namaPengawasSekretaris"
												label="Sekretaris Pengawas"
												icon="user-shield"
												group
												type="text"
												validate
												value={this.state.namaPengawasSekretaris}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="hpPengawasSekretaris"
												label="Hp HP Sekretaris Pengawas"
												icon="mobile-alt"
												group
												type="text"
												validate
												value={this.state.hpPengawasSekretaris}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="namaPengawasAnggota"
												label="Anggota Pengawas"
												icon="user-shield"
												group
												type="text"
												validate
												value={this.state.namaPengawasAnggota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="hpPengawasAnggota"
												label="No HP Anggota Pengawas"
												icon="mobile-alt"
												group
												type="text"
												validate
												value={this.state.hpPengawasAnggota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Periode Masa Bakti Kepengurusan Koperasi
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="4">
											<MDBInput
												name="masaBaktiTahun"
												label="Banyaknya Tahun"
												icon="user-clock"
												group
												type="number"
												validate
												value={this.state.masaBaktiTahun}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="masaBaktiAwal"
												label="Mulai Tahun"
												icon="calendar-check"
												group
												type="number"
												validate
												value={this.state.masaBaktiAwal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="masaBaktiAkhir"
												label="Sampai Dengan Tahun"
												icon="calendar-times"
												group
												type="number"
												validate
												value={this.state.masaBaktiAkhir}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Manager Koperasi</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="namaManager"
												label="Nama Manager"
												icon="user-secret"
												group
												type="text"
												validate
												value={this.state.namaManager}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Jumlah Karyawan/Anggota Penuh/Calon Anggota
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="4">
											<MDBInput
												name="jumlahKaryawanLaki"
												label="Jumlah Karyawan Laki-Laki"
												icon="mars-double"
												group
												type="number"
												validate
												value={this.state.jumlahKaryawanLaki}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jumlahKaryawanPerempuan"
												label="Jumlah Karyawan Perempuan"
												icon="venus-double"
												group
												type="number"
												validate
												value={this.state.jumlahKaryawanPerempuan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="Total Karyawan"
												icon="equals"
												name=""
												group
												type="number"
												validate
												disabled
												value={
													parseInt(this.state.jumlahKaryawanPerempuan) +
													parseInt(this.state.jumlahKaryawanLaki)
												}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jumlahAnggotaPenuhLaki"
												label="Jumlah Anggota Penuh Laki-Laki"
												icon="mars-double"
												group
												type="number"
												validate
												value={this.state.jumlahAnggotaPenuhLaki}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="jumlahAnggotaPenuhPerempuan"
												label="Jumlah Anggota Penuh Perempuan"
												icon="venus-double"
												group
												type="number"
												validate
												value={this.state.jumlahAnggotaPenuhPerempuan}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="4">
											<MDBInput
												label="Total Anggota Penuh"
												icon="equals"
												group
												type="number"
												validate
												disabled
												value={
													parseInt(this.state.jumlahAnggotaPenuhLaki) +
													parseInt(this.state.jumlahAnggotaPenuhPerempuan)
												}
											/>
										</MDBCol>
										<MDBCol md="4"></MDBCol>
										<MDBCol md="4">
											<MDBInput
												name="JumlahcalonAnggota"
												label="Jumlah Calon Anggota"
												icon="user-plus"
												group
												type="number"
												validate
												value={this.state.JumlahcalonAnggota}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">
										Pelaksanaan RAT 3 Tahun Terakhir
									</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="pelaksanaanRAT1Tahun"
												label="Pelaksanaan RAT Pertama Tahun:"
												icon="handshake"
												group
												type="number"
												validate
												value={this.state.pelaksanaanRAT1Tahun}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="pelaksanaanRAT1Tanggal"
												label="Pada Tanggal:"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.pelaksanaanRAT1Tanggal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="pelaksanaanRAT2Tahun"
												label="Pelaksanaan RAT Kedua Tahun"
												icon="handshake"
												group
												type="number"
												validate
												value={this.state.pelaksanaanRAT2Tahun}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="pelaksanaanRAT2Tanggal"
												label="Pada Tanggal:"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.pelaksanaanRAT2Tanggal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="pelaksanaanRAT3Tahun"
												label="Pelaksanaan RAT Ketiga Tahun"
												icon="handshake"
												group
												type="number"
												validate
												value={this.state.pelaksanaanRAT3Tahun}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="pelaksanaanRAT3Tanggal"
												label="Pada Tanggal:"
												icon="calendar-check"
												group
												type="date"
												validate
												value={this.state.pelaksanaanRAT3Tanggal}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Indikator Usaha</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3">
											<MDBInput
												name="indikatorUsahaModalSendiri"
												label="Indikator Usaha Modal Sendiri"
												icon="money-check-alt"
												group
												type="number"
												validate
												value={this.state.indikatorUsahaModalSendiri}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="indikatorUsahaModalLuar"
												label="Indikator Usaha Modal Luar"
												icon="money-bill-wave-alt"
												group
												type="number"
												validate
												value={this.state.indikatorUsahaModalLuar}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="indikatorUsahaAsset"
												label="Indikator Usaha Asset"
												icon="money-check"
												group
												type="number"
												validate
												value={this.state.indikatorUsahaAsset}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="indikatorSHU"
												label="Indikator Usaha SHU"
												icon="money-check-alt"
												group
												type="number"
												validate
												value={this.state.indikatorSHU}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Lokasi Google Maps</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="12" className="text-center">
											<MDBBtn
												href="https://www.openstreetmap.org/search?query=kotamobagu#map=14/0.7283/124.3065"
												target="_blank"
												gradient="aqua"
											>
												<MDBIcon icon="map-marked-alt fa-2x" />
											</MDBBtn>
										</MDBCol>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="latitude"
												label="Latitude"
												icon="map-marked"
												group
												type="text"
												validate
												value={this.state.latitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>
										<MDBCol md="3">
											<MDBInput
												name="longitude"
												label="Longitude"
												icon="map-marked-alt"
												group
												type="text"
												validate
												value={this.state.longitude}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Petugas Pendata</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="petugasPendata"
												label="Nama:"
												icon="file-signature"
												group
												type="text"
												validate
												value={this.state.petugasPendata}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
									<h6 className="text-center mt-3 text-primary font-italic">Petugas Pendata</h6>
									<hr className="text-center text-primary bg-primary w-50" />
									<MDBRow>
										<MDBCol md="3"></MDBCol>
										<MDBCol md="6">
											<MDBInput
												name="petugasPendata"
												label="Nama:"
												icon="file-signature"
												group
												type="text"
												validate
												value={this.state.petugasPendata}
												onChange={this.handlerChange}
											/>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</div>
							<div className="text-center">
								<MDBBtn color="primary" type="submit">
									Simpan
								</MDBBtn>
							</div>
						</form>
					</MDBModalBody>
				</MDBModal>
			</>
		);
	}
}

export default TambahKoperasi;
